import { Directive, Input, ElementRef, HostListener } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator }  from '@angular/forms';

// Services
import { NxCommonService } from '../../../core/services/nx-common.service';

@Directive({
  selector: '[nxValidateInput]',
  providers: [{ provide: NG_VALIDATORS, useExisting: NxInputValidatorDirective, multi: true }]
})
export class NxInputValidatorDirective implements Validator {

  @Input('nxValidateInput') type: string;

  private specialKeys: Array<string> = [ 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', '-' ];

  private NxFormInputTypes = {
    number  : { validation: 'number',   match: 'numbers' },
    numbers : { validation: '',         match: 'numbers' },
    decimal : { validation: 'decimal',  match: 'numbers' },
    currency: { validation: 'currency', match: 'numbers' },
    year    : { validation: 'year',     match: 'numbers' },
    phone   : { validation: 'phone',    match: 'numbers' },
    email   : { validation: 'email' },
    taxCode : { validation: 'taxCode' }
  };

  constructor(private el: ElementRef, private commonService: NxCommonService) { }

  /**
   * 
   */
  validate(control: AbstractControl): { [key: string]: any } {
    
    if (!this.type || !this.NxFormInputTypes[this.type])
      return null;

    const valid = !control.value || this.commonService.testField(this.getValidationType(), control.value);
    return valid ? null : { 'nxValidateInput': { value: control.value } };
  }

  /**
   * 
   */
  getValidationType(): string {

    return this.NxFormInputTypes[this.type].validation;
  }

  /**
   * 
   */
  getMatchType(): string {

    if (!this.type || !this.NxFormInputTypes[this.type])
      return;

    return this.NxFormInputTypes[this.type].match;
  }

  /**
   * 
   */
  getMatchRegex(): string {

    return this.commonService.regex[this.getMatchType()];
  }

  /**
   * 
   */
  @HostListener('keydown', [ '$event' ])
  onKeyDown(event: KeyboardEvent) {
	 	
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1)
      return;

    if (!this.getMatchType())
      return;

    let current: string = this.el.nativeElement.value;
    let next   : string = current.concat(event.key);

    if (next && !String(next).match(this.commonService.regex.numbers))
      event.preventDefault();
  }
}
