import { Component, OnInit, Input, ViewChildren }      from '@angular/core';
import { ViewChild, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute }                      from '@angular/router';
import { Observable }                                  from 'rxjs';

// Models
import { NxMenuItem, NxNavigationInfo }                from '../../../common/components/nx-navigation/nx-menu-item';
// Components
import { NxSideNavComponent }                          from '../../../common/components/nx-side-nav/nx-side-nav.component';
// Services
import { AuthService }                                 from '../../services/auth.service';
import { NxNavigationService }                         from '../../../common/components/nx-navigation/nx-navigation.service';
import { NxMessageService }                            from '../../../common/components/nx-message/nx-message.service';

@Component({
  selector     : 'nx-sidenav',
  templateUrl  : './nx-sidenav.component.html',
  styleUrls    : ['./nx-sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NxSidenavComponent implements OnInit, AfterViewInit {

  @Input() openMenu: boolean;
  @Input() fixed   : boolean = true;
  
  get navInfo(): NxNavigationInfo         { return this.navigation.getNavigationInfo() };
  get items()  : Observable<NxMenuItem[]> { return this.navigation.getMenu(); }

  @ViewChild(NxSideNavComponent) private sidenavComponent: NxSideNavComponent;
  
  @ViewChildren('menuItem') menuItem;

  constructor(private router        : Router,
              private auth          : AuthService,
              private activatedRoute: ActivatedRoute,
              private navigation    : NxNavigationService,
              private message       : NxMessageService) {}

  ngOnInit() {

    //this.navigation.getMenu().subscribe(console.log);
  }

  ngAfterViewInit(): void {

    this.menuItem.changes.subscribe((res) => {

      let sub = this.navigation.section.subscribe((res) => {

        if (!res)
          return;

        this.menuItem.toArray().forEach((el, index) => {
         
          if (res.section.name === el.body.bodyEl.nativeElement.parentElement.id) {
            
            if (!el.collapsed) {

              if (sub)
                sub.unsubscribe();

              return;
            }

            setTimeout(() => el.toggle());

            if (sub)
              sub.unsubscribe();
          }
        });  
      });
    });
  }

  /**
   * 
   */
  notAvailable(): void {

    this.message.showWarning('common.functionNotAvailable');
  }

  /**
   * 
   */
  getItemRoute(item: NxMenuItem): string | string[] {

    if (this.hasItems(item))
      return null;
      
    return item.routerLink;
  }

  /**
   * 
   */
  hasItems(item: NxMenuItem): boolean {

    return !!(item.items && item.items.length);
  }

  /**
   * 
   */
  isLoggedIn(): Observable<boolean> {

    return this.auth.isLoggedIn();
  }

  /**
   * 
   */
  show(): void {

    this.sidenavComponent.toggle();
  }

  /**
   * 
   * @param item 
   */
  showItem(item: NxMenuItem): Observable<any> {

    return this.auth.getUser().map(user => {

      return (user && user.roles && user.hasRoles(item.role));
    });
  }

  /**
   * 
   */
  isActive(item: NxMenuItem): boolean {

    if (!item)
      return;

    return item.id === this.navigation.routeId;
  }
}
