// Models
import { NxBaseField }      from './nx-base-field';
import { NxFormInputTypes } from './nx-form.enums';

export class NxTextareaField extends NxBaseField<string> {

  controlType = NxFormInputTypes.TEXTAREA;
  
  rows: number;

  constructor(options: {} = {}) {

    super(options);
    this.rows = options['rows'] || 1;
  }
}