import { NgModule, ModuleWithProviders } from '@angular/core';

// Modules
import { SharedBaseModule }              from '../../../shared/shared-base.module';
import { NxTabsetModule }                from '../nx-tabset/nx-tabset.module';
// Components
import { NxModalComponent }              from './nx-modal.component';
import { NxTemplateModalComponent }      from './nx-template-modal/nx-template-modal.component';
// Directives
import { NxModalContentDirective }       from './nx-modal-content.directive';
// Services
import { NxConfirmModalComponent }       from './nx-confirm-modal/nx-confirm-modal.component';
import { NxModalService }                from './nx-modal.service';
import { NxMobileModalComponent } from './nx-mobile-modal/nx-mobile-modal.component';

@NgModule({
  imports        : [ SharedBaseModule, NxTabsetModule ],
  exports        : [ NxModalComponent, NxConfirmModalComponent, NxMobileModalComponent, NxModalContentDirective ],
  declarations   : [ NxModalComponent, NxConfirmModalComponent, NxMobileModalComponent, NxModalContentDirective, NxTemplateModalComponent ],
  entryComponents: [ NxTemplateModalComponent, NxConfirmModalComponent, NxMobileModalComponent ],
  providers      : [ NxModalService ]
})
export class NxModalModule {

  static forRoot(): ModuleWithProviders {

    return {
      ngModule : NxModalModule,
      providers: [ ]
    };
  }
}
