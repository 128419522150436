// Models
import { NxObject }             from '../../common/utils';
import { OrganizationTypeCode } from './organization-type';



export interface OrganizationInterface {
  _id?      : string;
  code?     : string;
  name?     : string;
  type?     : string;
  parent?   : string;
  parentObj?: OrganizationInterface;
  users?    : any[];
  startDate?: Date;
  endDate?  : Date;
  data?     : NxObject;
}

export class Organization implements OrganizationInterface {
  _id?      : string;
  code?     : string;
  name?     : string;
  type?     : string;
  parent?   : string;
  parentObj?: OrganizationInterface;
  users?    : any[];
  startDate?: Date;
  endDate?  : Date;
  data?     : NxObject;

  constructor(obj: OrganizationInterface = {}) {
                      
    this._id       = obj._id;
    this.code      = obj.code;
    this.name      = obj.name;
    this.type      = obj.type;
    this.startDate = obj.startDate; 
    this.endDate   = obj.endDate;
    this.parent    = obj.parent;
    this.parentObj = obj.parentObj;
    this.data      = obj.data  || {};
    this.users     = obj.users || [];
  }

  /**
   * 
   */
  cleanData(): void {
    
    delete this.parentObj;
    delete this.users;
    delete this.data;
  }

  
  /**
   * 
   */
  isCompany(): boolean {

    return this.type === OrganizationTypeCode.COMPANY;
  }
}
