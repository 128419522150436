import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nxSelectItems'
})
export class NxSelectItemsPipe implements PipeTransform {

  transform(value: any, key: string = 'name', args?: any): any {

    if (!value)
      return [];

    return value.map((v) => { return { label: v[key], value: v } });
  }
}
