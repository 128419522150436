import { NxObject } from '../../../../common/utils';

export const CONFIRM_MODAL_ID   = 'nxConfirmModal';
export const SECONDARY_MODAL_ID = 'secondaryModal';
export const MOBILE_MODAL_ID    = 'nxMobileModal';

export const NX_MODAL_STYLE: NxObject = {
  info   : 'modal-notify modal-info',
  success: 'modal-notify modal-success',
  warning: 'modal-notify modal-warning',
  danger : 'modal-notify modal-danger'
}