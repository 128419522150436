import { NgModule, ModuleWithProviders  } from '@angular/core';
import { NxWavesEffectDirective } from './nx-waves-effect.directive';

@NgModule({
  declarations: [NxWavesEffectDirective],
  exports: [NxWavesEffectDirective]
})

export class NxWavesEffectModule {
  public static forRoot(): ModuleWithProviders {
    return {
    	ngModule: NxWavesEffectModule,
    	providers: []
    };
  }
}
