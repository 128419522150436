import { FileLineReader } from './file-line-reader';
import { NxFile }         from './nx-file';
import { NxObject }       from '.';

export class NxFileLineReader extends FileLineReader {

  private _nxFile: NxFile;

  set nxFile(nxFile: NxFile) {

    this._nxFile = nxFile;
    this.file    = nxFile.file;
  }

  /**
   * 
   * @param file 
   */
  readNx(nxFile: NxFile, options?: NxObject) {

    this.nxFile = nxFile;

    // clean file
    delete this._nxFile.data.items;
    delete this._nxFile.data.type;
    
    this.read(nxFile.file);
  }

    /**
   * 
   * @param line 
   */
  protected getEventData(line: string): NxObject {

    if (!this._nxFile.data.items)
      this._nxFile.data.items = [];

    this._nxFile.data.items.push(line);

    return { nxFileId: this._nxFile.id, item: line };
  }
}