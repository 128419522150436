import { Injectable }             from '@angular/core';
import { CanActivate, Router }    from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';

import 'rxjs/add/operator/skip';

// Services
import { AuthService }            from './auth.service';
import { NxNavigationService }    from '../../common/components/nx-navigation/nx-navigation.service';

@Injectable()
export class RouteGuardService implements CanActivate {

  constructor(private router    : Router,
              private auth      : AuthService,
              private navigation: NxNavigationService) { }

  /**
   * 
   */
  canActivate(route: ActivatedRouteSnapshot) {    
    
    return this.checkLogin(route, route.data);
  }

  /**
   * Check user auth and roles
   */
  checkLogin(route, data) {

    if (!this.auth.authToken) {

      this.navigation.sendToLogin();
      return false;
    }


    return !data || !data.roles || this.auth.getUser().map(user => {

      //console.log('checkLogin', user, roles);

      let res = true;

      // check id exists in user entities
      // if (!user.isAdmin() && data.checkId && user[data.checkId] && params.id)
      //   res = res && (user[data.checkId].indexOf(+params.id) !== -1);

      // check roles



      if (res && data && user.hasRoles(data.roles))
        return true;

      this.navigation.sendToError(403);
      return false;
    });
  }


}
