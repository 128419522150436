export class NxScrollbarOptions {
  height?    : number;  // Set scrollbar height
  trackX?    : boolean; // Horizontal scrollbar, default false
  trackY?    : boolean; // Vertical scrollbar, default true
  invertX?   : boolean; // Invert horizontal scrollbar position, default false
  invertY?   : boolean; // Invert vertical scrollbar position, default false
  shown?     : string;  // Configure when scrollbars should be shown , default native.
                        //   native: scrollbars are shown when content is scrollable.  
                        //   hover : scrollbars are shown when mouse is over the view port (and content is scrollable).
                        //   always: scrollbars are always shown.
  autoHide?  : boolean; // Hide scrollbars, and show them on hover, default false
  autoUpdate?: boolean; // Auto-update scrollbars on content changes, default: true
  viewClass? : string;  // Add custom class to the view, default: null
  barClass?  : string;  // Add custom class to scrollbars, default: null
  thumbClass?: string;  // Add custom class to scrollbars' thumbnails
  compact?   : boolean; // Make scrollbars position appears over content, default: false
  
  constructor(options?: NxScrollbarOptions | {}) {

    this.height     = options && options['height'];
    this.trackX     = options && options['trackX'];
    this.trackY     = options && options['trackY'];
    this.invertX    = options && options['invertX'];
    this.invertY    = options && options['invertY'];
    this.shown      = options && options['shown'];
    this.autoHide   = options && options['autoHide'];
    this.autoUpdate = options && options['autoUpdate'];
    this.viewClass  = options && options['viewClass'];
    this.barClass   = options && options['barClass'];
    this.thumbClass = options && options['thumbClass'];
    this.compact    = options && options['compact'];
  } 
}