import { NgModule }                     from '@angular/core';

// Modules
import { SharedBaseModule }             from '../../../shared/shared-base.module';
// Components
import { NxAccordionComponent }         from './nx-accordion.component';
import { NxAccordionItemComponent }     from './nx-accordion-item.component';
import { NxAccordionItemHeadComponent } from './nx-accordion-item-head.component';
import { NxAccordionItemBodyComponent } from './nx-accordion-item-body.component';

@NgModule({
  imports     : [ SharedBaseModule ],
  exports     : [ NxAccordionComponent, NxAccordionItemComponent, NxAccordionItemHeadComponent, NxAccordionItemBodyComponent ],
  declarations: [ NxAccordionComponent, NxAccordionItemComponent, NxAccordionItemHeadComponent, NxAccordionItemBodyComponent ]
})
export class NxAccordionModule { }
