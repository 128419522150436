import { animate, keyframes, state, style, trigger, transition } from '@angular/animations';
import { Component }                                             from '@angular/core';
import { Toast, ToastrService, ToastPackage }                    from 'ngx-toastr';

@Component({
  selector   : '[nx-message]',
  styleUrls  : ['./nx-message.component.scss'],
  templateUrl: './nx-message.component.html',
  animations : [
    trigger('flyInOut', [
      state('inactive', style({
        display: 'none',
        opacity: 0
      })),
      transition('inactive => active', animate('400ms ease-out', keyframes([
        style({
          transform: 'translate3d(0, 100%, 0)',
          opacity: 0
        }),
        style({
          transform: 'none',
          opacity: 1
        })
      ]))),
      transition('active => removed', animate('400ms ease-out', keyframes([
        style({
          opacity: 1
        }),
        style({
          transform: 'translate3d(0, 100%, 0)',
          opacity: 1
        }),
        style({
          opacity: 0
        })
      ])))
    ])
  ],
  preserveWhitespaces: false
})
export class NxMessageComponent extends Toast {

  constructor(protected toastrService: ToastrService,
              public    toastPackage : ToastPackage) {
	  
    super(toastrService, toastPackage);
  }

  /**
   * 
   */
  action(event: Event): boolean {
	  
    event.stopPropagation();
    this.toastPackage.triggerAction();
    
    this.remove();
    
    return false;
  }
}