// Models
import { NxBaseModalOptions }               from '../../nx-base-modal/nx-base-modal.options';
import { NxModalTabOptions, NxModalButton } from './nx-modal.types';
import { NxModalType }                      from './nx-modal.enums';

export interface NxModalOptionsInterface {
  title?      : string;
  titleIcon?  : string;
  type?       : string;
  style?      : string;
  modalClass? : string;
  headerClass?: string;
  titleClass? : string;
  bodyClass?  : string;
  cascading?  : boolean;
  centered?   : boolean;
  tabMode?    : boolean;
  showHeader? : boolean;
  showClose?  : boolean;
  showFooter? : boolean;
  modalConfig?: NxBaseModalOptions;
  tabs?       : NxModalTabOptions[];
  cancel?     : NxModalButton;
  confirm?    : NxModalButton;
  buttons?    : NxModalButton[];
  onHide?     : Function;
}

export class NxModalOptions implements NxModalOptionsInterface {
  title?      : string;
  titleIcon?  : string;
  type?       : string  = NxModalType.CENTRAL_LG;
  style?      : string  = '';
  modalClass? : string;
  headerClass?: string  = '';
  titleClass? : string  = '';
  bodyClass?  : string;
  cascading?  : boolean = false;
  centered?   : boolean = false;
  tabMode?    : boolean = false;
  showHeader? : boolean = true;
  showClose?  : boolean = true;
  showFooter? : boolean = true;
  modalConfig?: NxBaseModalOptions;
  tabs?       : NxModalTabOptions[];
  cancel?     : NxModalButton;
  confirm?    : NxModalButton;
  buttons?    : NxModalButton[];
  onHide?     : Function;

  constructor(options: NxModalOptionsInterface = {}) {
	
    this.title       = options.title;
    this.titleIcon   = options.titleIcon;
    this.type        = options.type        || NxModalType.CENTRAL_LG;
    this.style       = options.style;
    this.modalClass  = options.modalClass  || '';
    this.headerClass = options.headerClass || '';
    this.titleClass  = options.titleClass  || '';
    this.bodyClass   = options.bodyClass;
    this.tabMode     = options.tabMode;
    this.modalConfig = options.modalConfig || { ignoreBackdropClick: true, backdrop: 'static' };
    this.tabs        = options.tabs;
    this.cancel      = options.cancel;
    this.confirm     = options.confirm;
    this.buttons     = options.buttons;
    this.onHide      = options.onHide;
    
    if (options.cascading === true) 
      this.cascading = true;
    if (options.centered === true)
      this.centered = true;
    if (options.showHeader === false)
      this.showHeader = false;
    if (options.showFooter === false)
      this.showFooter = false;
    if (options.showClose === false)
      this.showClose = false;

    if (options.type === NxModalType.FRAME_BOTTOM || options.type === NxModalType.FRAME_TOP || 
        options.type === NxModalType.FLUID_BOTTOM || options.type === NxModalType.FLUID_TOP || 
        options.type === NxModalType.FLUID_RIGHT  || options.type === NxModalType.FLUID_LEFT ||
        options.type === NxModalType.FULL_PAGE) {
        
      this.cascading = false;
    }
    
    if (options.style)
      this.headerClass = '';
  }
}

export class NxModalComponentOptions {
  data?   : any;
  options?: any;
  events? : { key: string, method: Function }[];
}
