import { Directive, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[nxDateFormat]'
})
export class NxDateFormatDirective implements OnInit {
	
  resultLength    : number;
  separatorsNumber: number;

  @Input() separator = '/';
  @Input() format    = ['dd', 'mm', 'yyyy'];

  @HostListener('input', ['$event'])
  @HostListener('paste', ['$event'])
  onInput(event: any): void {
	  
    const currentValue = event.target.value;
    const newValue     = this.getFormattedDate(currentValue);
    event.target.value = newValue;
  }

  ngOnInit() {
	 
    this.setSeparatorsNumber();
    this.setResultLength();
  }

  /**
   * 
   */
  setSeparatorsNumber(): void {
	  
    this.separatorsNumber = this.format.length - 1;
  }

  /**
   * 
   */
  setResultLength(): void {
	  
    let resLength = 0;
    
    this.format.forEach((value) => { resLength += value.length; });
    
    this.resultLength = resLength + this.separatorsNumber;
  }

  /**
   * 
   * @param date 
   */
  getFormattedDate(date: string): string {
	  
    const dateParts = this.getDateParts(date);

    const result = dateParts.map((part, index) => {
      return part = this.formatDateParts(part, index);
    });

    return result.join(this.separator).slice(0, this.resultLength);
  }

  /**
   * 
   * @param date 
   */
  getDateParts(date: string): string[] {
		  
    date = this.getDigits(date).slice(0, this.resultLength - this.separatorsNumber);
    
    const parts: string[] = [];
    
    const partsIndex = {
      first: this.format[0].length,
      mid  : this.format[0].length + this.format[1].length,
      last : this.resultLength
    };

    parts[0] = date.slice(0, partsIndex.first);

    if (date.length > partsIndex.first) {
      parts[1] = date.slice(partsIndex.first, partsIndex.mid);
    }

    if (date.length > partsIndex.mid) {
      parts[2] = date.slice(partsIndex.mid, partsIndex.last);
    }

    return parts;
  }

  /**
   * 
   * @param value 
   */
  getDigits(value: string): string {
	  
    return value.replace(/\D/g, '');
  }

  /**
   * 
   * @param datePart 
   * @param index 
   */
  formatDateParts(datePart: any, index: number): string {
	  
    switch (this.format[index]) {
      case 'dd':
        datePart = this.getFormattedDay(datePart);
        break;
      case 'mm':
        datePart = this.getFormattedMonth(datePart);
        break;
    }

    return datePart;
  }

  /**
   * 
   * @param value 
   */
  getFormattedDay(value: string): string {
	  
    const dayFirstNum = parseInt(value.charAt(0), 10);
    
    if (value) {
    	
      if (dayFirstNum > 3 && dayFirstNum !== 0) {
        return '0' + value.charAt(0);
      } 
      else {
        return value;
      }
    }
  }

  /**
   * 
   * @param value 
   */
  getFormattedMonth(value: string): string {
	  
    const monthFirstNum = parseInt(value.charAt(0), 10);
    const monthNum      = parseInt(value, 10);

    if (value) {
    	
      if (monthFirstNum > 1 && monthFirstNum !== 0) {
        return '0' + value.charAt(0);
      } 
      else if (monthNum > 12) {
        return '12';
      } 
      else {
        return value;
      }
    }
  }
}
