import { FormGroup }        from '@angular/forms';

// Models
import { setAttr }          from '../../../../common/utils';
import { NxBaseField }      from './nx-base-field';
import { NxFormInputTypes } from './nx-form.enums';

export interface NxCheckboxFieldOptions {
  label: string; 
  value: any;
  key  : string;
}

export class NxCheckboxField extends NxBaseField<string> {

  controlType = NxFormInputTypes.CHECKBOX;
  
  options: NxCheckboxFieldOptions[] = [];
  inline : boolean;

  constructor(options: {} = {}) {

    super(options);
    
    this.options = options['options'] || [];
    this.inline  = !!options['inline'];
  }

  /**
   * 
   * @param option 
   * @param data 
   * @param form 
   */
  onChange(option: NxCheckboxFieldOptions, data: any, form: FormGroup): void {
    
    if (!option.key)
      return;

    setAttr(data, option.key, form.controls[option.key].value);
  }
}