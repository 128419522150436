import { Injectable }               from '@angular/core';
import { Router }                   from '@angular/router';
import { HttpHeaders, HttpClient }  from '@angular/common/http';
import { Apollo }                   from 'apollo-angular';
import * as moment                  from 'moment';

// Observable
import { BehaviorSubject, Subject } from 'rxjs';
import { Observable }               from 'rxjs';
import { tap, map }                 from 'rxjs/operators';

import                              'rxjs/add/operator/map';
import                              'rxjs/add/operator/filter';

// Models
import { loginQuery, logoutQuery }  from '../graphql/queries/auth.query';
import { sessionQuery }             from '../graphql/queries/auth.query';
import { User }                     from '../../admin/models';
// Services
import { NxConfigService }          from './nx-config.service';
import { NxCryptService }           from './nx-crypt.service';
import { NxMessageService }         from '../../common/components/nx-message/nx-message.service';
import { NxObject } from 'src/app/common/utils';

export type LoginResponse = { token: string, user: User };

export interface AuthResponse {
  login?      : LoginResponse;
  logout?     : boolean;
  sessionUser?: User;
}

@Injectable()
export class AuthService {

  //private user   : User;
  storageItems = {
    token             : 'token',
    uname             : 'uname',
    expirePasswordDate: 'expireDate',
    operatorData      : 'operator'
  };

  private loggedIn = new BehaviorSubject<boolean>(this.hasToken());
  private user     = new BehaviorSubject<User>(new User());

  constructor(private http            : HttpClient,
              private apollo          : Apollo,
              private router          : Router,
              private nxMessageService: NxMessageService,
              private nxConfigService : NxConfigService,
              private nxCryptService  : NxCryptService) {

    // this.config.setBaseUrlByUser(this.username);
    // console.log(this.config.baseUrl);
  }

  /**
   * 
   */
  get authToken(): string {

    if (!localStorage.getItem(this.storageItems.token))
      return null;

    // return this.crypt.decrypt(localStorage.getItem(this.storageItems.token));
    return localStorage.getItem(this.storageItems.token);
  }

  /**
   * 
   */
  set authToken(text: string) {

    if (!text) {

      localStorage.removeItem(this.storageItems.token);
      return;
    }

    localStorage.setItem(this.storageItems.token, text);//this.crypt.encrypt(text));
  }

  /**
   * 
   */
  get username(): string {

    if (!localStorage.getItem(this.storageItems.uname))
      return null;

    return this.nxCryptService.decrypt(localStorage.getItem(this.storageItems.uname));
  }

  /**
   * 
   */
  set username(text: string) {

    if (!text) {

      localStorage.removeItem(this.storageItems.uname);
      return;
    }

    localStorage.setItem(this.storageItems.uname, this.nxCryptService.encrypt(text));
  }

  set userOperatorData(data: NxObject) {

    !data ? localStorage.removeItem(this.storageItems.operatorData) : localStorage.setItem(this.storageItems.operatorData, this.nxCryptService.encrypt(JSON.stringify(data)));
  }

  get userOperatorData(): NxObject {

    return JSON.parse(this.nxCryptService.decrypt(localStorage.getItem(this.storageItems.operatorData)));
  }

  /**
   * 
   */
  get expirePasswordDate(): string {

    if (!localStorage.getItem(this.storageItems.expirePasswordDate))
      return null;

    return this.nxCryptService.decrypt(localStorage.getItem(this.storageItems.expirePasswordDate));
  }

  /**
   * 
   */
  set expirePasswordDate(date: string) {

    if (!date) {

      localStorage.removeItem(this.storageItems.expirePasswordDate);
      return;
    }

    localStorage.setItem(this.storageItems.expirePasswordDate, this.nxCryptService.encrypt(date));
  }

  /**
   * Check current Authorization by REST
   */
  checkSessionREST(): void {

    this.http.post<User>(`${this.nxConfigService.endpointRest}/session`, {}).subscribe(
      res => this.user.next(res),
      err => {}
    );
  }

  /**
   * Check current Authorization by GRAPHQL
   */
  checkSession(): void {

    if (!this.hasToken()) 
      return this.cleanSession();


    this.apollo.query<AuthResponse>({ query: sessionQuery })
                .map(({ data }) => data.sessionUser)
                .subscribe(user => this.user.next(new User(user)));
  }

  /**
   * clean current session
   */
  cleanSession(): void {

    this.user.next(new User());
    this.loggedIn.next(false);
    localStorage.clear();
    sessionStorage.clear();

    this.apollo.getClient().clearStore();

    this.router.navigate(['/login']);
  }

  /**
   * Get observable occurrence of current user
   *
   * @returns {Observable<User>}
   */
  getUser(): Observable<User> {

    //return this.user;
    return this.user.asObservable().filter(user => !!user._id);
  }

  /**
   *
   * @returns {Observable<T>}
   */
  isLoggedIn(): Observable<boolean> {

    return this.loggedIn.asObservable();
  }

  /**
   * Login user by REST
   */
  loginREST(user: User): void {

    let token = btoa(`${user.username}:${user.password}`);

    this.nxConfigService.setBaseUrlByUser(user.username);

    this.http.post<User>(`${this.nxConfigService.endpointRest}/login`, {}, {
      headers: new HttpHeaders({'Authorization': `Basic ${token}` })
    })
    .subscribe(
      (data: User) => {

        this.user.next(data);

        this.authToken = token;
        this.username  = data.username;
        this.router.navigate(['/']);
      },
      err => this.nxMessageService.showError('Username o password errate')
    );
  }

  /**
   * Login user by GRAPHQL
   * 
   * @param user 
   */
  login(user: User): Observable<LoginResponse> {

    return this.apollo.mutate<AuthResponse>({
      mutation : loginQuery,
      variables: { username: user.username, password: user.password }
    })
    .pipe(
      map(({ data }) => {

        if (data.login.token !== 'EXPIRED') {
          this.user.next(new User(data.login.user));
          this.loggedIn.next(true);
          this.authToken = data.login.token;
          this.expirePasswordDate = data.login.user.services.password.expiresAt;
          this.userOperatorData = data.login.user.operatorData;
          this.username = this.user.value.username;
        }

        return data.login;
      }) 
    );
  }
  
  /**
   * Logout user by REST
   */
  logoutREST(): void {

    // logout api -> add with new auth method
    // go to login
    this.cleanSession();
  }

  /**
   * Logout user by GRAPHQL
   */
  logout(): void {

    this.apollo.mutate<AuthResponse>({ mutation: logoutQuery }).subscribe(res => {
            
      // clean session and go to login
      this.cleanSession();
    });
  }

  /**
   * 
   */
  private getRemainingDays(): number {

    let remaining = Math.floor(moment.duration(moment(new Date(this.expirePasswordDate)).diff(moment(new Date()))).asDays());
    return remaining;
  }

  /**
   * if we have token the user is loggedIn
   *
   * @returns {boolean}
   */
  private hasToken(): boolean {

    return !!localStorage.getItem(this.storageItems.token);
  }

  /**
   * 
   */
  private sendExpirePasswordMessage(days: number): void {
    
    this.nxMessageService.showWarning(`La tua password scadrà tra ${days} giorni`, 'AVVISO SCADENZA PASSWORD', { timeOut: 100000, closeButton: true });
  }
}
