export class CodedEntityBase {

  _id?           : string;
  id?            : number;
  code?          : string;
  name?          : string;
  description?   : string;
  

  constructor(obj: CodedEntityBase = {}) {
                      
    this._id            = obj._id;
    this.id             = obj.id;
    this.code           = obj.code;
    this.name           = obj.name;
    this.description    = obj.description;
    
  }
}