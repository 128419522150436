import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router }                                  from '@angular/router';

import * as moment from 'moment';

// Models
import { User }                                    from '../../../admin/models';
// Services
import { AuthService }                             from '../../services/auth.service';
import { NxConfigService }                         from '../../services/nx-config.service';
import { isMobile }                                from '../../../common/utils/';

@Component({
  selector   : 'nx-header',
  templateUrl: './nx-header.component.html',
  styleUrls  : ['./nx-header.component.scss']
})
export class NxHeaderComponent implements OnInit {

  @Output() openMenu: EventEmitter<any> = new EventEmitter();

  isOpen: boolean;
  user  : User;

  get isLoggedIn() { return this.auth.isLoggedIn(); }

  constructor(private router: Router, private auth: AuthService, private nxConfigService: NxConfigService) {

    //this.auth.checkSession();
  }

  ngOnInit() {

    this.auth.getUser().subscribe(data => this.user = data);
  }

  showMenu() {
    return !isMobile();
  }
  /**
   * 
   */
  getCurrentDate(): string {

    return moment().locale('it').format('dddd DD MMMM YYYY');
  }
  
  /**
   * 
   */
  getVersion(): string {

    return this.nxConfigService.version;
  }
  
  /**
   * 
   */
  getBuild(): string {

    return this.nxConfigService.build;
  }

  /**
   * 
   */
  getUser(): User {

    return this.user;
  }

  /**
   * 
   */
  openSideMenu(): void {

    if (!this.showMenu())
      return;
      
    this.isOpen = !this.isOpen;
    this.openMenu.emit(this.isOpen);
  }

  /**
   * 
   */
  isSnapped(): boolean {

    return this.isOpen;
  }

  /**
   * 
   */
  logout(): void {

    this.auth.logout();
    // this.confirmationService.confirm({
    //   message: 'Sei sicuro di voler uscire?',
    //   accept : () => {

    //     this.auth.logout();
    //   }
    // });
  }
}
