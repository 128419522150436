import { NxObject } from '../../../../common/utils';

export enum NxMessageType {
  topLeft         = 'toast-top-left', 
  topCenter       = 'toast-top-center', 
  topRight        = 'toast-top-right', 
  topFullWidth    = 'toast-top-full-width',
  bottomLeft      = 'toast-bottom-left', 
  bottomCenter    = 'toast-bottom-center',
  bottomRight     = 'toast-bottom-right',
  bottomFullWidth = 'toast-bottom-full-width'
}
