import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { NxDropdownState } from './nx-dropdown.state';

@Directive({
  selector: '[nxDropdownMenu],[dropdownMenu]',
  exportAs: 'nx-dropdown-menu'
})
export class NxDropdownMenuDirective {
	
  constructor(_state: NxDropdownState,
    _viewContainer: ViewContainerRef,
    _templateRef: TemplateRef<any>) {
    _state.resolveDropdownMenu({
      templateRef: _templateRef,
      viewContainer: _viewContainer
    });
  }
}
