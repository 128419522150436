import { ModuleWithProviders }          from '@angular/core';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule }                 from '@angular/common';
import { RouterModule }                 from '@angular/router';
import { HttpClientModule }             from '@angular/common/http';
import { TranslateModule }              from '@ngx-translate/core';
import { HttpLinkModule }               from 'apollo-angular-link-http';

// Models
import { NxConfig }                     from './models';
// Interceptor
import { httpInterceptorProviders }     from './http-interceptors';
// Services
import { AbstractHttpService }          from './services/abstract-http.service';
import { AuthService }                  from './services/auth.service';
import { ComboService }                 from './services/combo.service';
import { NxCommonService }              from './services/nx-common.service';
import { NxConfigService }              from './services/nx-config.service';
import { NxCryptService }               from './services/nx-crypt.service';
import { NxRequestCacheService }        from './services/nx-request-cache.service';
import { RouteGuardService }            from './services/route-guard.service';
import { NxExportService }              from './services/nx-export.service';
// Modules
import { GraphQLModule }                from './graphql/graphql.module';
import { NxModalModule }                from '../common/components/nx-modal/nx-modal.module';
import { NxMessageModule }              from '../common/components/nx-message/nx-message.module';
import { NxAccordionModule }            from '../common/components/nx-accordion/nx-accordion.module';
import { NxNavbarModule }               from '../common/components/nx-navbar/nx-navbar.module';
import { NxSideNavModule }              from '../common/components/nx-side-nav/nx-side-nav.module';
import { NxProgressbarModule }          from '../common/components/nx-progressbar/nx-progressbar.module';
import { NxSideNavAccordionModule }     from '../common/components/nx-side-nav-accordion/nx-side-nav-accordion.module';
import { NxNavigationContextModule }    from '../common/components/nx-navigation-context/nx-navigation-context.module';
import { NxPipesModule }                from '../common/pipes/nx-pipes.module';
import { NxWavesEffectModule }          from '../common/directives/nx-waves-effect/nx-waves-effect.module';
import { NxTooltipModule }              from '../common/directives/nx-tooltip/nx-tooltip.module';
import { NxDropdownModule }             from '../common/directives/nx-dropdown/nx-dropdown.module';
import { NxTabsetModule }               from '../common/components/nx-tabset/nx-tabset.module';
// Components
import { NxHeaderComponent }            from './components/nx-header/nx-header.component';
import { NxSidenavComponent }           from './components/nx-sidenav/nx-sidenav.component';
import { NxBreadcrumbComponent }        from './components/nx-breadcrumb/nx-breadcrumb.component';
import { NxNavigationContextComponent } from '../common/components/nx-navigation-context/nx-navigation-context.component';
// Directives
import { NxPreventDropDirective }       from '../common/directives/nx-prevent-drop.directive';
// Services
import { NxPdfService }                 from './services/nx-pdf.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule, // provides HttpClient for HttpLink
    HttpLinkModule,
    RouterModule,
    GraphQLModule, // Apollo,
    NxModalModule.forRoot(),
    NxTabsetModule.forRoot(),
    NxMessageModule.forRoot(),
    TranslateModule,
    NxAccordionModule, 
    NxNavbarModule,
    NxSideNavModule,
    NxSideNavAccordionModule, 
    NxNavigationContextModule,
    NxPipesModule.forRoot(),
    NxWavesEffectModule,
    NxTooltipModule.forRoot(),
    NxDropdownModule.forRoot(),
    NxProgressbarModule.forRoot()
  ],
  declarations: [ NxSidenavComponent, NxHeaderComponent, NxPreventDropDirective, NxBreadcrumbComponent ],
  exports     : [ NxSidenavComponent, NxHeaderComponent, NxPreventDropDirective, NxBreadcrumbComponent, NxModalModule ],
  providers   : [ RouteGuardService/*,  OLD LIBSSpinningPreloader */]
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {

    if (parentModule)
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
  }

  /**
   * 
   * @param config 
   */
  static forRoot(config: NxConfig): ModuleWithProviders {

    return {
      ngModule : CoreModule,
      providers: [
        httpInterceptorProviders,
        { provide: NxConfig, useValue: config },
        NxCommonService,
        ComboService,
        AbstractHttpService,
        AuthService,
        NxConfigService,
        NxCryptService,
        NxRequestCacheService,
        NxExportService,
        NxPdfService
      ]
    };
  }
}
