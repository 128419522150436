import { Injector, Pipe, PipeTransform }                    from '@angular/core';
import { DecimalPipe, CurrencyPipe, PercentPipe, DatePipe } from '@angular/common';

// Models
import { NxValueType }                                      from '../models';
// Pipes
import { NxFileSizePipe }                                   from './nx-file-size.pipe';
import { NxBooleanValuePipe }                               from './nx-boolean-value.pipe';

@Pipe({
  name: 'nxValueParser'
})
export class NxValueParserPipe implements PipeTransform {

  public constructor(private injector?: Injector) { }

  transform(value: any, columnType: NxValueType, pipeArgs: any[]): any {

    if (!columnType)
      return value;
    
    if (value === undefined || value === null)
      return '-';

    let correctPipe: Pipe;
      
    switch (columnType) {
      
      case NxValueType.CURRENCY:
        correctPipe = CurrencyPipe;
        pipeArgs    = pipeArgs || ['EUR', 'symbol', '0.2-2'];
        break;
      case NxValueType.DECIMAL:
        correctPipe = DecimalPipe; 
        pipeArgs    = pipeArgs || ['0.2-2'];
        break;
      case NxValueType.NUMBER:
        correctPipe = DecimalPipe;
        break;
      case NxValueType.PERCENTAGE:

        if (pipeArgs && pipeArgs[1])
          value = value/100;
 
        correctPipe = PercentPipe;
        pipeArgs    = (pipeArgs && pipeArgs.slice(0,1)) || ['0.0'];
        break;
      case NxValueType.FILESIZE:
        correctPipe = NxFileSizePipe;
        break;
      case NxValueType.DATE:
        correctPipe = DatePipe;
        pipeArgs    = pipeArgs || ['shortDate'];
        break;
      case NxValueType.DATETIME:
        correctPipe = DatePipe;
        pipeArgs    = pipeArgs || ['short'];
        break;
      case NxValueType.BOOLEAN:
        correctPipe = NxBooleanValuePipe;
        break;
      case NxValueType.STRING:
      case NxValueType.STRING_WRAP:
      case NxValueType.HTML:
      case NxValueType.LINK:
      case NxValueType.ICON:
      case NxValueType.BADGE:
      return value;
    }

    let pipe = this.injector.get(correctPipe);
    
    return pipe.transform(value, ...pipeArgs);
  }
}
