export class NxConfig {

  baseUrl: string = 'http://localhost:3000';
  version: string = '0.0.0';
  build  : string = 'xxxxxx';
  api = {
    rest   : 'api/rest',
    graphql: 'api/graphql'
  };
  endpoints = [
    {
      url  : 'http://localhost:3000',
      users: []
    }
  ];
}