import { NgModule }     from '@angular/core';
import { CommonModule } from '@angular/common';

// Directives
import { NxInputValidatorDirective } from './nx-input-validator.directive';
import { NxNoWhitespaceDirective }   from './nx-no-whitespaces.directive';
import { NxInputDirective }          from './nx-input.directive';
import { NxCheckboxDirective }       from './nx-checkbox.directive';
import { NxRadioDirective }          from './nx-radio.directive';
import { NxDateFormatDirective }     from './nx-date-format.directive';


@NgModule({
    imports     : [ CommonModule ],
    exports     : [ NxInputValidatorDirective, NxNoWhitespaceDirective, NxInputDirective, NxCheckboxDirective, NxRadioDirective, NxDateFormatDirective ],
    declarations: [ NxInputValidatorDirective, NxNoWhitespaceDirective, NxInputDirective, NxCheckboxDirective, NxRadioDirective, NxDateFormatDirective ],
    providers   : []
})
export class NxFormDirectivesModule { }