import { ValidatorFn }                      from '@angular/forms';

// Models
import { NxValueType }                      from '../../../models';
import { NxSelectItem, NxPropertyFunction } from '../../../utils';
import { NxField, NxBooleanProperty }       from '../../../utils';
import { NxLinkedField, NxEntityRef }       from './nx-dynamic-form.types';
import { NxFormInputTypes }                 from './nx-form.enums';
import { NxBinderType }                     from '../../nx-binder/models';
import { Observable } from 'rxjs';

export interface NxBaseFieldInterface<T> extends NxField {
  value?            : T;
  required?         : boolean;
  removable?        : boolean;
  order?            : number;
  controlType?      : NxFormInputTypes;
  type?             : NxValueType;
  colClass?         : string;
  disabled?         : NxBooleanProperty;
  fieldClass?       : string;
  validators?       : ValidatorFn[];
  placeholder?      : string;
  appendTo?         : string;
  internal?         : boolean;
  onLabel?          : string;
  offLabel?         : string;
  maxDateField?     : any;
  minDateField?     : any;
  maxLength?        : number;
  options?          : any;
  binderType?       : NxBinderType;
  entityRef?        : NxEntityRef;
  linkedFields?     : NxLinkedField[];
  selectOptions?    : Observable<any[]> |NxSelectItem[] | any[];
  pattern?          : any;
  badgeField?       : string;
  labelField?       : string;
  valueField?       : string;
  selectedItems?    : any;
  selectedValue?    : (data) => void;
  locale?           : string;
  translate?        : boolean;
  inline?           : boolean;
  changed?          : NxPropertyFunction<any>;
  blur?             : NxPropertyFunction<any>;
  max?              : number;
  min?              : number;
  rows?             : number;
  acceptWhitespaces?: boolean;
}

export class NxBaseField<T> implements NxBaseFieldInterface<T> {
  value?        : T;
  required?     : boolean;
  removable?    : boolean;
  controlType?  : NxFormInputTypes;
  key?          : string;
  label?        : string;
  type?         : NxValueType;
  hidden?       : NxBooleanProperty;
  order?        : number;
  colClass?     : string;
  disabled?     : NxBooleanProperty;
  fieldClass?   : string;
  validators?   : ValidatorFn[];
  placeholder?  : string;
  appendTo?     : string;
  internal?     : boolean;
  onLabel?      : string;
  offLabel?     : string;
  maxDateField? : any;
  minDateField? : any;
  maxLength?    : number;
  options?      : any;
  binderType?   : NxBinderType;
  entityRef?    : NxEntityRef;
  linkedFields? : NxLinkedField[];
  selectOptions?: Observable<any[]> | NxSelectItem[] | any[];
  pattern?      : any;
  labelField?   : string;
  valueField?   : string;
  selectedItems?: any;
  selectedValue?: (data) => void
  locale?       : string;
  translate?    : boolean;
  inline?       : boolean;
  changed?      : NxPropertyFunction<any>;
  blur?         : NxPropertyFunction<any>;
  min?          : number;
  max?          : number;
  rows?         : number;

  constructor(options: NxBaseFieldInterface<T> = {}) {

    this.value         = options.value || null;
    this.controlType   = options.controlType || NxFormInputTypes.TEXT;
    this.key           = options.key         || '';
    this.label         = options.label       || '';
    this.type          = options.type;
    this.hidden        = options.hidden;
    this.colClass      = options.colClass    || 'col-6';
    this.order         = options.order === undefined ? 99 : options.order;
    this.required      = !!options.required;
    this.removable     = !!options.removable;
    this.disabled      = options.disabled;
    this.fieldClass    = options.fieldClass     || '';
    this.validators    = options.validators     || [];
    this.internal      = !!options.internal;
    this.onLabel       = options.onLabel        || '';
    this.offLabel      = options.offLabel       || '';
    this.maxLength     = options.maxLength;
    this.linkedFields  = options.linkedFields;
    this.binderType    = options.binderType;
    this.entityRef     = options.entityRef;
    this.selectOptions = options.selectOptions;
    this.pattern       = options.pattern        || null;
    this.labelField    = options.labelField;  
    this.valueField    = options.valueField;
    this.selectedItems = options.selectedItems  || [];
    this.locale        = options.locale;
    this.translate     = options.translate;
    this.appendTo      = options.appendTo;
    this.inline        = options.inline         || false;
    this.changed       = options.changed        || (() => {});
    this.selectedValue = options.selectedValue  || (() => {});
    this.blur          = options.blur           || null;
    this.min           = options.min            || 0;
    this.max           = options.max            || 100;
    this.rows          = options.rows           || 1;
  }

  /**
   * 
   */
  manageValue(value: any): any {

    this.value = value;
    return this.value;
  }
}