import { Injectable }                           from '@angular/core';
import { TranslateService }                     from '@ngx-translate/core';

// Models
import { NxValueType }                          from '../../common/models';
import { NxSelectionType, NxAggregationMethod } from '../../common/components/nx-table/models';
import { NxSelectItem, NxObject, NxDatePeriod }               from '../../common/utils';

/*
export interface ComboItem {
  
  id?        : string;
  label      : string;
  labelShort?: string;
  value?     : any;
  icon?      : string;
  className? : string;
}
*/

interface PageSizesOption {
  max : number;
  tick: number;
}

const NX_BASE_FORMAT_LIST = {
  date: [
    { label: 'ddMmYyyySlash',                value: 'dd/MM/yyyy' },            // 'ddMmYyyySlash'
    { label: 'mmDdYyyySlash',                value: 'MM/dd/yyyy' },            // 'mmDdYyyySlash'
    { label: 'ddMonYyyySlash',               value: 'dd/MMM/yyyy' },           // 'ddMonYyyySlash'
    { label: 'MonDdYyyySlash',               value: 'MMM/dd/yyyy' },           // 'MonDdYyyySlash'
    { label: 'ddMmYyyyDash',                 value: 'dd-MM-yyyy' },            // 'ddMmYyyyDash'
    { label: 'mmDdYyyyDash',                 value: 'MM-dd-yyyy' },            // 'mmDdYyyyDash'
    { label: 'ddMonYyyyDash',                value: 'dd-MMM-yyyy' },           // 'ddMonYyyyDash'
    { label: 'monDdYyyyMonth',               value: 'MMM-dd-yyyy' },           // 'monDdYyyyMonth'
    { label: 'extendeDayOfWeekddMonthYyyy',  value: 'EEEE, dd MMMM yyyy' },    // 'extendeDayOfWeekddMonthYyyy'
    { label: 'extendedMonthDdDayOfWeekYyyy', value: 'MMMM, dd EEEE, yyyy' }    // 'extendedMonthDdDayOfWeekYyyy'
  ],
  decimal: [      
    { label: 'noDecimals',          value: '0.0-0' },      // noDecimals
    { label: 'oneDecimal',          value: '0.1-1' },      // oneDecimal
    { label: 'oneOrTwoDecimals',    value: '0.1-2' },      // oneOrTwoDecimals
    { label: 'twoDecimals',         value: '0.2-2' },      // twoDecimals
    { label: 'twoOrThreeDecimals',  value: '0.2-3' },      // twoOrThreeDecimals
    { label: 'threeDecimals',       value: '0.3-3' },      // threeDecimals
    { label: 'threeOrFourDecimals', value: '0.3-4' },      // threeOrFourDecimals
    { label: 'fourDecimals',        value: '0.4-4' },      // fourDecimals
    { label: 'fourOrFiveDecimals',  value: '0.4-4' }       // fourOrFiveDecimals
  ]
};

const NX_PAGINATION_SIZE_LIST = [
  { label: 'sizeSmall', value: 'sm' }, 
  { label: 'sizeLarge', value: 'lg' } 
];

@Injectable()
export class ComboService {

  constructor(private translateService: TranslateService) {}

  /*

  yesNo(): ComboItem[] {

    return [
      { id: 'YES', label: 'Si' },
      { id: 'NO',  label: 'No' }
    ];// as ComboItem[];
  }

  gender(): ComboItem[] {

    return [
      { id: 'M', label: 'Maschio', icon: '', className: 'male'   },
      { id: 'F', label: 'Femmina', icon: '', className: 'female' }
    ];// as ComboItem[];
  }

  calendarLocale(): any {

    return {
      firstDayOfWeek : 1,
      dayNames       : ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
      dayNamesShort  : ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
      dayNamesMin    : ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
      monthNames     : ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
      monthNamesShort: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu','Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
      today          : 'Oggi',
      clear          : 'Pulisci'
    };
  }

  weekdays(): ComboItem[] {

    return [
      { id: 'LU', labelShort: 'Lun', label: 'Lunedì',    value: 0 },
      { id: 'MA', labelShort: 'Mar', label: 'Martedì',   value: 1 },
      { id: 'ME', labelShort: 'Mer', label: 'Mercoledì', value: 2 },
      { id: 'GI', labelShort: 'Gio', label: 'Giovedì',   value: 3 },
      { id: 'VE', labelShort: 'Ven', label: 'Venerdì',   value: 4 },
      { id: 'SA', labelShort: 'Sab', label: 'Sabato',    value: 5 }
    ];
  }

  datePickerItalianOptions: IMyOptions = {
    // Strings and translations 
    dayLabels      : { su: 'Dom',      mo: 'Lun',    tu: 'Mar',     we: 'Mer',       th: 'Gio',     fr: 'Ven',     sa: 'Sab' },
    dayLabelsFull  : { su: "Domenica", mo: "Lunedì", tu: "Martedì", we: "Mercoledì", th: "Giovedì", fr: "Venerdì", sa: "Sabato" },
    monthLabels    : { 1: 'Gen',     2: 'Feb',      3: 'Mar',   4: 'Apr',    5: 'Mag',    6: 'Giu',    7: 'Lug',    8: 'Ago',    9: 'Set',       10: 'Ott',     11: 'Nov',      12: 'Dic' },
    monthLabelsFull: { 1: "Gennaio", 2: "Febbraio", 3: "Marzo", 4: "Aprile", 5: "Maggio", 6: "Giugno", 7: "Luglio", 8: "Agosto", 9: "Settembre", 10: "Ottobre", 11: "Novembre", 12: "Dicembre" },
    dateFormat     : 'dd.mm.yyyy',
  };

 */

  /**
   * 
   */
  getDatePeriods(): NxSelectItem[] {
    
    return [
      { label: 'common.period.date.quarter1',     value: NxDatePeriod.QUARTER_1 },
      { label: 'common.period.date.quarter2',     value: NxDatePeriod.QUARTER_2 },
      { label: 'common.period.date.quarter3',     value: NxDatePeriod.QUARTER_3 },
      { label: 'common.period.date.quarter4',     value: NxDatePeriod.QUARTER_4 },
      { label: 'common.period.date.semester1',    value: NxDatePeriod.SEMESTER_1 },
      { label: 'common.period.date.semester2',    value: NxDatePeriod.SEMESTER_2 },
      { label: 'common.period.date.year',         value: NxDatePeriod.YEAR },
      { label: 'common.period.date.currmonth',    value: NxDatePeriod.CURR_MONTH },
      { label: 'common.period.date.lastMonth',    value: NxDatePeriod.LAST_MONTH },
      { label: 'common.period.date.currquarter',  value: NxDatePeriod.CURR_QUARTER },
      { label: 'common.period.date.lastQuarter',  value: NxDatePeriod.LAST_QUARTER },
      { label: 'common.period.date.currsemester', value: NxDatePeriod.CURR_SEMESTER},
      { label: 'common.period.date.lastSemester', value: NxDatePeriod.LAST_SEMESTER},
      { label: 'common.period.date.curryear',     value: NxDatePeriod.CURR_YEAR },
      { label: 'common.period.date.lastYear',     value: NxDatePeriod.LAST_YEAR }
    ];
  }

  /**
   * 
   * @param valueType 
   * @param baseLabel 
   */
  getBuilderFormatList(valueType: NxValueType, baseLabel?: string): NxSelectItem[] {

    let list;

    switch (valueType) {
      
      case NxValueType.DATE:
        list = this.getList(NxValueType.DATE,    baseLabel);
        break;
      case NxValueType.DECIMAL:
        list = this.getList(NxValueType.DECIMAL, baseLabel);
        break;
      case NxValueType.DATETIME:
        list = this.getList(NxValueType.DATE,    baseLabel, { param: 'hh:mm' });
        break;
      case NxValueType.PERCENTAGE:
        list = this.getList(NxValueType.DECIMAL, baseLabel, { param: '%' });
        break;
      case NxValueType.CURRENCY: 
        list = this.getList(NxValueType.DECIMAL, baseLabel, { param: '$' });
      break;
      case NxValueType.FILESIZE: 
        list = this.getList(NxValueType.DECIMAL, baseLabel, { param: 'Mb' });
        break;
      default:
        break;
    }

    return list;
  }

  /**
   * 
   * @param psListOptions 
   * @param baseLabel 
   */
  getBuilderPageSizes(psListOptions: PageSizesOption[], baseLabel?: string): NxSelectItem[] {
    
    return psListOptions.map((option) => {
    	
      let label       = !!baseLabel ? baseLabel : 'components.nxPagination.pageSizeItem';
      let labelParams = { start: option.tick, end: option.max };
      let data = [];
      
      for (let currentValue = option.tick; currentValue <= option.max; currentValue += option.tick)
        data.push(currentValue);
      
      return { label: this.translateService.instant(label, labelParams), value: data };
    });
  }

  /**
   * 
   * @param baseLabel 
   */
  getAggregationMethods(baseLabel?: string): NxSelectItem[] {
  
    let data = [];
    
    Object.keys(NxAggregationMethod).forEach(el => {
    
      let label = !!baseLabel ? `${baseLabel}.${NxAggregationMethod[el]}`: NxAggregationMethod[el];
      data.push({ label: label, value: NxAggregationMethod[el] });
    });
  
    return data;
  }
  
  /**
   * 
   * @param baseLabel 
   */
  getSelectionTypeList(baseLabel?: string): NxSelectItem[] {

    let data = [];
    
    Object.keys(NxSelectionType).forEach(el => {
    	
      data.push({ key: el, label: !!baseLabel ? `${baseLabel}.${NxSelectionType[el]}` : NxSelectionType[el], value: NxSelectionType[el] });
    });
    
    return data;
  }

  /**
   * 
   */
  getSizeList(): NxSelectItem[] {

    return NX_PAGINATION_SIZE_LIST;
  }

  /**
   * 
   */
  getValueTypes(baseLabel?: string): NxSelectItem[] {
    
    let data = [];

    Object.keys(NxValueType).forEach(el => {
    
      data.push({ label: !!baseLabel ? `${baseLabel}.${NxValueType[el]}` : NxValueType[el], value: NxValueType[el] });
    });
    
    let list = data.filter(el => {
      return el.value !== NxValueType.HTML        && 
             el.value !== NxValueType.BADGE       &&
             el.value !== NxValueType.ICON        &&
             el.value !== NxValueType.LINK        &&
             el.value !== NxValueType.PROGRESS    &&
             el.value !== NxValueType.SELECT      &&
             el.value !== NxValueType.STRING_WRAP &&
             el.value !== NxValueType.SWITCH;
    });

    return list;
  }

  /**
   * 
   */
  getChartNumberFormats(isPercentage?: boolean): NxSelectItem[] {
    
    let suffix = isPercentage ? '%' : '';

    return [
      { label: `#${suffix}`,      value: `#${suffix}`      },
      { label: `#.0${suffix}`,    value: `#.0${suffix}`    },
      { label: `#.00${suffix}`,   value: `#.00${suffix}`   },
      { label: `#.000${suffix}`,  value: `#.000${suffix}`  },
      { label: `#.0000${suffix}`, value: `#.0000${suffix}` }
    ];
  }

  /**
   * 
   */
  private getList(baseValueType: NxValueType, baseLabel?: string, params?: NxObject): NxSelectItem[] {

    let list = NX_BASE_FORMAT_LIST[baseValueType].map(el => { 
      
      let label = !!baseLabel                                    ? `${baseLabel}.${baseValueType}.${el.label}` : el.value;
      let value = baseValueType === NxValueType.DATE && !!params ? `${el.value} ${params.param}`               : el.value;

      return { label: this.translateService.instant(label, params || { param: '' }), value };
    });

    return list;
  }
}
