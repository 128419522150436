import { NxElementSize }                        from '../../../models';
import { DEFAULT_PAGINATION, NxPagination }     from '../../nx-pagination/models';
import { NxTableOptions }                       from './nx-table-options.interface';
import { NxPlaceholderOptions }                 from '../../../../common/components/nx-placeholder/models';
import { NxUploaderOptions }                    from '../../nx-uploader';
import { clone, NxAction }                      from '../../../../common/utils';
import { NxSelectionType }                      from './nx-table.enums';
import { NxTableColumn }                        from './nx-table-column.interface';
import { NxTableAction }                        from './nx-table-action.interface';
import { NxTableExportOptions, NxOrderPreset }  from './nx-table.types';

export class NxTableConfig implements NxTableOptions {

  id?                : string;
  model?             : string;
  cardView?          : boolean;
  search?            : boolean;
  hover?             : boolean;
  editable?          : boolean;
  editColumns?       : boolean;
  select?            : boolean;
  selectionType?     : NxSelectionType;
  columns            : NxTableColumn[];
  footerColumns      : NxTableColumn[];
  pagination?        : NxPagination;
  actions?           : NxTableAction[];
  hasFab?            : boolean;
  buttonClass?       : string;
  icon?              : string;
  size?              : NxElementSize;
  tableClass?        : string;
  //choosableColumns?  : boolean;
  placeholderOptions?: NxPlaceholderOptions;
  exports?           : NxTableExportOptions;
  imports?           : NxUploaderOptions;
  orderPreset?       : NxOrderPreset;
  menuActions?       : NxAction[];
  
  constructor() {

    this.cardView      = false;
    this.search        = false;
    this.selectionType = NxSelectionType.SINGLE;
    this.columns       = [];
    this.pagination    = clone(DEFAULT_PAGINATION);
  }

  /**
   * Update current configuration parameters
   * 
   * @param value 
   */
  update(value: NxTableOptions = { columns: [] }): void {

    this.cardView           = !!value.cardView;
    this.search             = !!value.search;
    this.hover              = !!value.hover;
    this.hasFab             = !!value.hasFab;
    this.editable           = !!value.editable;
    this.editColumns        = !!value.editColumns;
    this.select             = value.selectionType && (value.selectionType !== NxSelectionType.NONE);
    this.selectionType      = value.selectionType || NxSelectionType.NONE;
    this.actions            = value.actions;
    this.columns            = value.columns || [];
    this.footerColumns      = value.footerColumns;
    this.buttonClass        = value.buttonClass;
    this.icon               = value.icon;
    this.size               = value.size;
    this.tableClass         = value.tableClass;
    this.placeholderOptions = value.placeholderOptions;
    this.exports            = value.exports;
    this.imports            = value.imports;
    this.orderPreset        = value.orderPreset;
    this.model              = value.model;
    this.menuActions        = value.menuActions;

    this.columns.forEach(col => {

      if (!col.headerTooltip)
        col.headerTooltip = { text: col.label };
      
      if (col.composeStr && col.composeStr.length)
        col.compose = new Function('item', col.composeStr);

      if (col.aggregation && col.aggregation.composeStr && col.aggregation.composeStr.length)
        col.aggregation.compose = new Function('values', 'items', col.aggregation.composeStr);
      
      this.editColumns = !this.editable && (this.editColumns || !!col.choosable);
    });

    if (!value.pagination) {

      this.pagination = null;
      return;
    }
    else if (!Object.keys(value.pagination).length)
      value.pagination = clone(DEFAULT_PAGINATION);

    if (!this.pagination)
      this.pagination = clone(DEFAULT_PAGINATION);

    this.pagination.server         = !!value.pagination.server;
    this.pagination.disabled       = !!value.pagination.disabled;
    this.pagination.hidePageSize   = !!value.pagination.hidePageSize;
    this.pagination.ellipsis       = !!value.pagination.ellipsis;
    this.pagination.rotate         = !!value.pagination.rotate;
    this.pagination.boundaryLinks  = !!value.pagination.boundaryLinks;
    this.pagination.directionLinks = !!value.pagination.directionLinks;

    //if (value.pagination.pageSizeValues)
    this.pagination.pageSizeValues = value.pagination.pageSizeValues || DEFAULT_PAGINATION.pageSizeValues;

    //if (value.pagination.pageSize)
    this.pagination.pageSize = value.pagination.pageSize || DEFAULT_PAGINATION.pageSize;

    //if (value.pagination.maxSize)
    this.pagination.maxSize = value.pagination.maxSize || DEFAULT_PAGINATION.maxSize;

    if (value.pagination.size)
      this.pagination.size = value.pagination.size;
    else if (this.size)
      this.pagination.size = this.size;
  }
}