import { NgModule, ModuleWithProviders }                                  from '@angular/core';
import { CommonModule, DecimalPipe, CurrencyPipe, PercentPipe, DatePipe } from '@angular/common';

// Pipes
import { NxCapitalizePipe }   from './nx-capitalize.pipe';
import { NxFileSizePipe }     from './nx-file-size.pipe';
import { NxJsonPrettyPipe }   from './nx-json-pretty.pipe';
import { NxObjectArrayPipe }  from './nx-object-array.pipe';
import { NxOrderByPipe }      from './nx-order-by.pipe';
import { NxSafePipe }         from './nx-safe.pipe';
import { NxSafeHtmlPipe }     from './nx-safe-html.pipe';
import { NxSelectItemsPipe }  from './nx-select-items.pipe';
import { NxValueParserPipe }  from './nx-value-parser.pipe';
import { NxBooleanValuePipe } from './nx-boolean-value.pipe';

// Pipe
@NgModule({
  imports     : [ CommonModule ],
  exports     : [ 
    NxCapitalizePipe, 
    NxFileSizePipe, 
    NxObjectArrayPipe, 
    NxOrderByPipe,
    NxSelectItemsPipe, 
    NxValueParserPipe, 
    NxSafePipe, 
    NxSafeHtmlPipe, 
    NxJsonPrettyPipe, 
    NxBooleanValuePipe 
  ],
  declarations: [ 
    NxCapitalizePipe, 
    NxFileSizePipe, 
    NxObjectArrayPipe, 
    NxOrderByPipe,
    NxSelectItemsPipe, 
    NxValueParserPipe, 
    NxSafePipe, 
    NxSafeHtmlPipe, 
    NxJsonPrettyPipe, 
    NxBooleanValuePipe 
  ]
})
export class NxPipesModule {

  static forRoot(): ModuleWithProviders {
    return { 
      ngModule : NxPipesModule, 
      providers: [ 
        DecimalPipe, 
        CurrencyPipe, 
        PercentPipe, 
        DatePipe, 
        NxCapitalizePipe, 
        NxFileSizePipe, 
        NxObjectArrayPipe, 
        NxOrderByPipe,
        NxSelectItemsPipe, 
        NxValueParserPipe, 
        NxSafePipe, 
        NxSafeHtmlPipe, 
        NxJsonPrettyPipe, 
        NxBooleanValuePipe 
      ] 
    };
  }
}
