import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';

import { NxBarComponent } from './nx-bar.component';
import { NxProgressDirective } from './nx-progress.directive';
import { NxProgressbarComponent } from './nx-progressbar.component';
import { NxProgressbarConfigComponent } from './nx-progressbar.config.component';
import { NxProgressSpinnerComponent } from './nx-progress-spinner.component';

import { NxSpinnerComponent } from './nx-progress-spinner-module/nx-progress-spinner.component';

@NgModule({
  imports: [CommonModule],
  declarations: [NxProgressDirective, NxSpinnerComponent, NxBarComponent, NxProgressbarComponent, NxProgressSpinnerComponent],
  exports: [NxProgressDirective, NxSpinnerComponent, NxBarComponent, NxProgressbarComponent, NxProgressSpinnerComponent]
})
export class NxProgressbarModule {
  public static forRoot(): ModuleWithProviders {
    return {ngModule: NxProgressbarModule, providers: [NxProgressbarConfigComponent]};
  }
}
