import { Component, Input, TemplateRef } from '@angular/core';
import { NxModalBodyComponent } from '../models';

@Component({
  selector  : 'nx-template-modal',
  template  : '<ng-container [ngTemplateOutlet]="options?.template" [ngTemplateOutletContext]="{ data: data, options: options?.options }"></ng-container>',
  styleUrls : ['./nx-template-modal.component.scss']
})
export class NxTemplateModalComponent implements NxModalBodyComponent {
  
  @Input() data   : any;
  @Input() options: { template: TemplateRef<any>; options: any; };
  
  constructor() { }
}