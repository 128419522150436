import { HttpEventType, HttpEvent } from '@angular/common/http';
import { Subscription }             from 'rxjs';

// Models
import { uuid }                     from '../functs';
import { BaseEmitter }              from './base-emitter';

export const NxFileEvents = {
  progress: 'progress',
  done    : 'done'
};

export const NxFileContentTypes = {
  txt: 'text/plain',
  xml: 'text/xml'
};

export class NxFile extends BaseEmitter {

  id          : string;
  remoteId?   : string;
  file        : File;
  data?       : any;
  subscription: Subscription;
  progress    : number  = 0;
  uploaded    : boolean = false;

  constructor(file: File, data?: any) {
    
    super();

    this.id   = uuid();
    this.file = file;
    this.data = data || {};
  }

  /**
   * 
   * @param event 
   */
  manageUploadEvent(event: HttpEvent<any>) {

    let data: any = { file: { id: this.id, name: this.file.name } };

    switch (event.type) {

      // progress
      case HttpEventType.UploadProgress:
      
        this.progress = data.progress = event.loaded / event.total * 100;   
        console.log('PROGRESS', this.progress);
        this.emit({ type: NxFileEvents.progress, data: data });
        break;
      // end
      case HttpEventType.Response:


        let remoteFiles  = (event.body && event.body.files) || [];
        let uploadedFile = remoteFiles.find(f => f.originalname === this.file.name);

        this.uploaded = true;
        this.remoteId = uploadedFile && uploadedFile.id;

        this.emit({ type: NxFileEvents.done, data });
        
        break;
    }
  }

  /**
   * 
   * @param callback 
   */
  subscribeUpload(callback: (res) => any): void {

    if (this.subscription)
      this.subscription.unsubscribe();

    this.subscription = this.getEmitter().subscribe(callback);
  }
}