import { EventEmitter }        from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface BaseEmitterEvent {
  type : string;
  data?: any;
}

export class BaseEmitter {
  
  dispatcher_old: EventEmitter<BaseEmitterEvent> = new EventEmitter();

  protected dispatcher: Subject<BaseEmitterEvent> = new Subject<BaseEmitterEvent>();
  
  constructor() { }
  
  getEmitter_old(): EventEmitter<BaseEmitterEvent>  {

    return this.dispatcher_old;
  }

  getEmitter(): Observable<BaseEmitterEvent> {

    return this.dispatcher.asObservable();
  }

  emit(evt: BaseEmitterEvent): void {

    this.dispatcher.next(evt);
  }
}