import { Component, Input, Output, EventEmitter } from '@angular/core';

// Models
import { NxObject }                               from '../../../common/utils';
// Components
import { NxAccordionItemComponent }               from './nx-accordion-item.component';

@Component({
  selector   : 'nx-accordion-item-head',
  templateUrl: './nx-accordion-item-head.component.html'
})
export class NxAccordionItemHeadComponent {
	
  @Input() isDisabled : boolean = false;
  @Input() customClass: string;
  @Input() indicator  : boolean = true;

  @Output() onToggle?: EventEmitter<NxObject> = new EventEmitter();

  constructor(private accordionItem: NxAccordionItemComponent) { }

  /**
   * 
   * @param event 
   */
  toggleClick(event: any): void {
	  
    event.preventDefault();
    
    if (!this.isDisabled) {
    
      this.accordionItem.collapsed = !this.accordionItem.collapsed;
      this.accordionItem.toggle(this.accordionItem.collapsed);

      this.onToggle.emit({ collapsed: this.accordionItem.collapsed });
    }
  }
}
