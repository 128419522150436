import { Pipe, PipeTransform }           from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'nxSafe'
})
export class NxSafePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }

  /**
   * 
   * @param url 
   */
  transform(url): SafeResourceUrl {

    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}