import {Component, Input, ContentChildren, QueryList, forwardRef} from '@angular/core';
import {NxSideNavAccordionItemComponent} from './nx-side-nav-accordion-item';
import {NxSideNavAccordionConfig} from './nx-side-nav-accordion.config';

@Component({
  //exportAs: 'squeezebox',
  selector: 'nx-side-nav-accordion',
  templateUrl: 'nx-side-nav-accordion.html'
})
export class NxSideNavAccordionComponent {

  @Input() multiple = true;

  @ContentChildren(forwardRef(() => NxSideNavAccordionItemComponent)) items: QueryList<NxSideNavAccordionItemComponent>;

  constructor() {
	  NxSideNavAccordionConfig.serviceInstance = this;
  }

  didItemToggled(item: NxSideNavAccordionItemComponent) {
    // on not multiple, it will collpase the rest of items
    if (!this.multiple) {
      this.items.toArray().forEach(function(i) {
        if (i !== item) {
          i.applyToggle(true);
        }
      });
    }
  }

}
