export enum NxColumnSize {
  XS       = 'xs',
  SM       = 'sm',
  MD       = 'md',
  LG       = 'lg',
  XL       = 'xl',
  BADGE    = 'badge',
  DATE     = 'date', 
  DATETIME = 'datetime', 
  ICON     = 'icon', 
  PROGRESS = 'progress', 
  SWITCH   = 'switch'
}

export enum NxSortOrder {
  DESC, ASC, NONE, NA
}

export enum NxSelectionType {
  SINGLE = 'single', 
  MULTI  = 'multi', 
  NONE   = 'none'
}

export enum NxAggregationMethod {
  SUM = 'sum',
  AVG = 'avg',
  MED = 'med',
  MIN = 'min',
  MAX = 'max'
}

export enum NxAggregationType {
  DATA    = 'DATA',
  TEXT    = 'TEXT',
  DEFAULT = 'DEFAULT',
  NONE    = 'NONE'
}