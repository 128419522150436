export const DEFAULT_PAGINATION = {
  server        : false,
  disabled      : false,
  pageSizeValues: [10, 15, 20, 25, 30],
  pageSize      : 10,
  maxSize       : 10,
  ellipsis      : false,
  rotate        : true,
  boundaryLinks : true,
  directionLinks: false
}