import { Injectable }    from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { NxMessageOptions, NxMessageType } from './models';

@Injectable({
  providedIn: 'root'
})
export class NxMessageService {

  snackbarOptions: NxMessageOptions;
  messages       : string[] = [];

  private toastOptions: NxMessageOptions = { 
    progressBar  : false, 
    closeButton  : false, 
    tapToDismiss : false,
    actionText   : 'common.buttons.close',
    actionClass  : 'white-text',
    positionClass: NxMessageType.bottomCenter, 
    maxOpened    : 4 
  };

  constructor(private toastrService: ToastrService) { }

  /**
   * 
   * @param message 
   */
  add(message: string): void {

    this.messages.push(message);
  }

  /**
   * 
   * @param options 
   */
  getOptions(options: NxMessageOptions): NxMessageOptions {

    if (!options)
      return this.toastOptions;

    return Object.assign({}, this.toastOptions, options);
  }
  
  /**
   * 
   * @param message 
   * @param title 
   * @param options 
   */
  showDefault(message: string, title?: string, options?: NxMessageOptions): void {

    this.toastrService.show(message, title, this.getOptions(options));
    this.add(message);
  }

  /**
   * 
   * @param message 
   * @param title 
   * @param options 
   */
  showInfo(message: string, title?: string, options?: NxMessageOptions): void {
    console.log('showInfo');
    this.toastrService.info(message, title, this.getOptions(options));
    this.add(message);
  }

  /**
   * 
   * @param message 
   * @param title 
   * @param options 
   */
  showSuccess(message: string, title?: string, options?: NxMessageOptions): void {

    this.toastrService.success(message, title, this.getOptions(options));
    this.add(message);
  }

  /**
   * 
   * @param message 
   * @param title 
   * @param options 
   */
  showWarning(message: string, title?: string, options?: NxMessageOptions): void {

    this.clear();
    this.toastrService.warning(message, title, this.getOptions(options));
    this.add(message);
  }

  /**
   * 
   * @param message 
   * @param title 
   * @param options 
   */
  showError(message: string, title?: string, options?: NxMessageOptions): void {

    this.clear();
    this.toastrService.error(message, title, this.getOptions(options));
    this.add(message);
  }

  /**
   * Remove all or a single toast by id
   * @param id 
   */
  clear(id?) {

    this.toastrService.clear(id);
  }

  /**
   * 
   */
  // private clear(): void {

  //   this.messages = [];
  // }

  /**
   * 
   * @param data 
   */
  private showMessage(data: any): void {

    this.add(data.detail);
  }
}
