import { NgModule }                  from '@angular/core';
import { Routes, RouterModule }      from '@angular/router';

// Modules
import { NxNavigationModule }        from './common/components/nx-navigation/nx-navigation.module';
import { BaseNavigationModule }      from './base/base-navigation-module';
import { AdminNavigationModule }     from './admin/admin-navigation-module';
import { CovidNavigationModule}      from './covid/covid-navigation.module'; 
import { InfoNavigationModule}      from './info/info-navigation.module'; 
// Models
import { NxMenuItem }                from './common/components/nx-navigation/nx-menu-item';
// Services
import { RouteGuardService }         from './core/services/route-guard.service';
// Components
// import { HomeComponent }          from './base/home/home.component';
// import { LoginComponent }         from './base/login/login.component';

const routes: Routes = [
/*
  { 
    path         : '',
    loadChildren : './base/base.module#BaseModule' 
  },
  { 
    path: 'ns',  
    loadChildren: './night_shifts/ns.module#NsModule',            
    data: { breadcrumb: [{ label: 'Shift Planner',        section: true }] } 
  },
  { 
    path: 'cp',    
    loadChildren: './capacity_planner/cp.module#CpModule',        
    data: { breadcrumb: [{ label: 'OR Capacity Planner', section: true }] } 
  },  
*/
  { 
    path         : '',
    loadChildren : './base/base.module#BaseModule' 
  },
  { 
    path         : 'covidInfo',
    loadChildren : './info/info.module#InfoModule',
    data         : { breadcrumb: [{ label: 'covid.navigation.info',  section: true }] } 
  },
  { 
    path         : 'covidPlanner',
    loadChildren : './covid/covid.module#CovidModule',
    data         : { breadcrumb: [{ label: 'covid.navigation.title',  section: true }] } 
  },
  { 
    path         : 'admin',
    loadChildren : './admin/admin.module#AdminModule',
    data         : { breadcrumb: [{ label: 'admin.navigation.title',  section: true }] } 
  },
  { 
    path      : '**',
    redirectTo: '' 
  }
];

// define here menu from submodules
const menu: NxMenuItem[] = [];

@NgModule({
  imports  : [ 
    RouterModule.forRoot(routes), 
    NxNavigationModule.forRoot([/*{ name: 'app', label: 'APP', icon: 'mdi mdi-format-line-weight', role: [], routerLink: [] }*/]),
    AdminNavigationModule,
    InfoNavigationModule,
    CovidNavigationModule
  ],
  exports  : [ RouterModule ],
  providers: []
})
export class AppRoutingModule { }
