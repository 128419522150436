import { Observable, BehaviorSubject } from 'rxjs';
import { switchMap }                   from 'rxjs/operators';

// Models
import { NxBaseField }                 from './nx-base-field';
import { NxFormInputTypes }            from './nx-form.enums';
import { NxSelectItem, NxObject }      from '../../../../common/utils';

export class NxSelectField extends NxBaseField<string> {

  controlType = NxFormInputTypes.SELECT;

  service       : any;
  selectOptions?: NxSelectItem[] = [];
  selectOptions$: Observable<NxSelectItem[]>;
  filterSub     : BehaviorSubject<NxObject> = new BehaviorSubject<NxObject>({});
  placeholder   : string;

  constructor(options: {} = {}) {

    super(options);

    this.service       = options['service'];
    this.selectOptions = options['selectOptions'] || [];
    this.placeholder   = options['placeholder']   || 'components.nxSelect.choose';
    this.appendTo      = options['appendTo']      || 'body';

    if (this.service) {

      this.filterSub.next(this.entityRef.filter || {});
      this.selectOptions$ = this.filterSub.pipe(switchMap(data => (this.labelField) ? this.service.list(data) : this.service.selectItems(data)));
    }
    else
      this.selectOptions$ = (this.selectOptions instanceof Observable) ? this.selectOptions : Observable.of(this.selectOptions);
  }

  /**
   * 
   * @param filter 
   */
  updateOptions(filter?: NxObject): void {

    if (!this.entityRef)
      return;
    
    if (!this.entityRef.filter)
      this.entityRef.filter = {};
    
    Object.assign(this.entityRef.filter, filter);

    this.filterSub.next(this.entityRef.filter || {});
  }
}