import { Component, OnInit, ComponentFactoryResolver, Input, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector : 'nx-navigation-context',
  template : '<ng-container #container></ng-container>',
  styleUrls: ['./nx-navigation-context.component.scss']
})
export class NxNavigationContextComponent implements OnInit {

  @Input() context;

  @ViewChild('container', { read: ViewContainerRef }) private container: ViewContainerRef;

  constructor(private componentFactoryResolver?: ComponentFactoryResolver) { }

  ngOnInit() {

    let componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.context);
    this.container.createComponent(componentFactory);
  }
}
