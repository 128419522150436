import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';

import { NgTranscludeDirective } from './transclude.directive';
import { NxTabPillHeadingDirective } from './nx-tab-pill-heading.directive';
import { NxTabPillDirective } from './nx-tab-pill.directive';
import { NxTabsetComponent } from './nx-tabset.component';
import { NxTabsetConfig } from './nx-tabset.config';

@NgModule({
  imports: [CommonModule],
  declarations: [NgTranscludeDirective, NxTabPillDirective, NxTabsetComponent, NxTabPillHeadingDirective],
  exports: [NxTabPillDirective, NxTabsetComponent, NxTabPillHeadingDirective, NgTranscludeDirective]
})
export class NxTabsetModule {
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: NxTabsetModule,
      providers: [NxTabsetConfig]
    };
  }
}
