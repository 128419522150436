import { Component, ElementRef, ViewChild, Input }    from '@angular/core';
import { state, style, trigger, transition, animate } from '@angular/animations';

// Services
import { NxAccordionService }                         from './nx-accordion.service';

@Component({
  selector   : 'nx-accordion-item-body',
  templateUrl: './nx-accordion-item-body.component.html',
  animations : [
    trigger('expandBody', [
      state('collapsed', style({ height: '0px', display: 'none' })),
      state('expanded',  style({ height: '*', display: 'block' })),
      transition('expanded <=> collapsed', animate('500ms ease'))
    ])
  ]
})
export class NxAccordionItemBodyComponent {
	
  @Input() customClass: string;

  height              : string = '0';
  animationState      : string = 'idle';
  expandAnimationState: string = 'collapsed';
  
  @ViewChild('body') bodyEl: ElementRef;

  constructor(private accordionService: NxAccordionService) { }

  /**
   * Init item body according collapsed attribute
   * prevent first opening animation
   * @param collapsed 
   */
  init(collapsed: boolean) {

    if (collapsed) {

      this.height               = '0';
      this.expandAnimationState = 'collapsed';
      return;
    }
      
    this.expandAnimationState = 'expanded';
    this.height               = '*';
  }

  /**
   * 
   */
  toggle(collapsed: boolean): void {
	  
    setTimeout(() => {
      collapsed ? this.expandAnimationState = 'collapsed' : this.expandAnimationState = 'expanded';
    }, 0);
  }
  
  /**
   * 
   */
  animationStart(event: any): void {
       
    this.animationState = 'start';
    
    if (event.fromState !== 'void')
	    this.accordionService.changeAnimationState({ state: this.animationState, event: event });
  }
  
  /**
   * 
   */
  animationDone(event: any): void {
  
    this.animationState = 'done';
    
    if (event.fromState !== 'void')
	    this.accordionService.changeAnimationState({ state: this.animationState, event: event });
  }
}
