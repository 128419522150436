import { switchMap }                                         from 'rxjs/operators';
import { Observable, BehaviorSubject }                       from 'rxjs';

// Models
import { NxBaseField }                                       from './nx-base-field';
import { NxFormInputTypes }                                  from './nx-form.enums';
import { NxBinderOptions, NxDataItemsActions, NxBinderType } from '../../nx-binder/models';
import { coerceBooleanProperty, NxObject, setAttr }          from '../../../../common/utils';

export class NxBinderField extends NxBaseField<any[]> {

  controlType = NxFormInputTypes.BINDER;

  options       : NxBinderOptions;
  binderType    : NxBinderType;
  service       : any;
  dataSource$   : Observable<any[]>;
  filterSub     : BehaviorSubject<NxObject> = new BehaviorSubject<NxObject>({});
  includeExclude: boolean;
  multiple      : boolean;
  preventLoad   : boolean;
  autocomplete  : boolean;
  virtualScroll : boolean;
  translate     : boolean;
  badgeField    : string;
  searchField   : string = 'text';
  items         : any[] | Observable<any[]>;
  
  dataSource = (searchTerm: string) => {
 
    if (!this.entityRef)
      return;

    if (!this.entityRef.filter)
      this.entityRef.filter = {};

    if (searchTerm)
      this.entityRef.filter[this.searchField] = searchTerm;
    else
      delete this.entityRef.filter[this.searchField];
    
    this.filterSub.next(this.entityRef.filter);
    
    return this.dataSource$;
  };

  constructor(options: {} = {}) {

    super(options);

    this.options        = Object.assign({ chipClass: 'chip-sm', chipIcon: 'mdi-circle' },  options['options'] || {});
    this.service        = options['service'];
    this.includeExclude = options['includeExclude'];
    this.translate      = options['translate'];
    this.multiple       = coerceBooleanProperty(options['multiple']);
    this.preventLoad    = coerceBooleanProperty(options['preventLoad']);
    this.autocomplete   = coerceBooleanProperty(options['autocomplete']);
    this.virtualScroll  = coerceBooleanProperty(options['virtualScroll']);
    this.selectOptions  = options['selectOptions'];
    this.badgeField     = options['badgeField'];
    this.searchField    = options['searchField'] || (this.autocomplete ? 'text' : 'name');
    this.binderType     = options['binderType'];

    // if (this.binderType === NxBinderType.TEXT || this.binderType === NxBinderType.NUMBER)
    //   return;

    if (this.service) {
     
      this.filterSub.next(this.entityRef.filter || {});

      let serviceMethod = this.entityRef.method || 'list';

      this.dataSource$ = this.filterSub.pipe(
        switchMap(data => {

          let getData = !this.preventLoad || !!(this.autocomplete && data[this.searchField]);
          // if not autocomplete use cached data to better performaces
          return (getData) ? this.service[serviceMethod](data, { fetchPolicy: this.autocomplete ? 'network-only' : 'cache-first'}) : Observable.of([]); 
        })
      );
    }
    else {

      this.items      = this.selectOptions || [];
      this.dataSource = null;
    }
  }

  /**
   * 
   * @param filter 
   */
  updateOptions(filter?: NxObject): void {

    if (!this.entityRef)
      return;

    if (!this.entityRef.filter)
      this.entityRef.filter = {};
    
    Object.assign(this.entityRef.filter, filter);

    this.filterSub.next(this.entityRef.filter || {});
  }

  /**
   * 
   * @param value 
   */
  manageValue(value: any[]): any[] {

    // this.value = this.valueField ? value.map(i => i[this.valueField]) : value;
    // return this.value;

    this.value = value;
    return this.value;
  }

  /**
   * 
   * @param itemAction 
   */
  selected(itemAction: NxDataItemsActions, data: any): void {
  
    this.manageValue(itemAction.selectedItems);
    setAttr(data, this.key, this.value);
  }
}