// Models
import { NxBaseField }      from './nx-base-field';
import { NxFormInputTypes } from './nx-form.enums';

export class NxPasswordConfirmField extends NxBaseField<string> {

  controlType = NxFormInputTypes.PASSWORDCONFIRM;
  
  constructor(options: {} = {}) {

    super(options);
  }
}