import { NgModule }                     from '@angular/core';

// Modules
import { SharedBaseModule }             from '../../../shared/shared-base.module';
// Components
import { NxNavigationContextComponent } from './nx-navigation-context.component';

@NgModule({
  imports     : [ SharedBaseModule ],
  declarations: [ NxNavigationContextComponent ],
  exports     : [ NxNavigationContextComponent ]
})
export class NxNavigationContextModule { }
