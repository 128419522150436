import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nxCapitalize'
})
export class NxCapitalizePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    
    if (!value)
      return '';

    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}
