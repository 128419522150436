import { Injectable, Optional } from '@angular/core';

// Models
import { NxConfig }             from '../models';

@Injectable()
export class NxConfigService {

  private config: NxConfig;

  get version()        : string { return this.config.version; }
  get build()          : string { return this.config.build; }
  get baseUrl()        : string { return this.config.baseUrl; }
  get endpointRest()   : string { return `${this.config.baseUrl}/${this.config.api.rest}`; }
  get endpointGraphQL(): string { return `${this.config.baseUrl}/${this.config.api.graphql}`; }

  constructor(@Optional() config: NxConfig) {

    //console.log('NxConfigService', config);
    this.config = config || new NxConfig();
  }

  /**
   * 
   */
  setBaseUrlByUser(username: string): void {

    if (!username)
      return;

    for (var i = 0; i < this.config.endpoints.length; i++) {

      if (this.config.endpoints[i].users.indexOf(username) !== -1) {

        this.config.baseUrl = this.config.endpoints[i].url;
        break;
      }
    }
  }
}
