import { Directive, }                            from '@angular/core';
import { Validator, NG_VALIDATORS, FormControl } from '@angular/forms';

/**
 * This validator works like "required" but it does not allow whitespace either
 *
 * @export
 * @class NoWhitespaceDirective
 * @implements {Validator}
 */
@Directive({
  selector: '[nxNoWhitespaces]',
  providers: [{ provide: NG_VALIDATORS, useExisting: NxNoWhitespaceDirective, multi: true }]
})
export class NxNoWhitespaceDirective implements Validator {

  /**
   * 
   * @param control 
   */
  validate(control: FormControl): { [key: string]: any } {
    return this.noWhitespaceValidator(control);
  }

  /**
   * 
   * @param control 
   */
  noWhitespaceValidator(control: FormControl) {

    if (!control.value)
      return;

    const hasWhitespace = !(control.value || '').trim().length;
    const isValid = !hasWhitespace;

    return isValid ? null : { 'valueNotPresent': true };
  }
}