import {Component} from '@angular/core';

@Component({
  selector: 'nx-logo, nx-navbar-brand',
  template: `
    <ng-content></ng-content>
  `
})
export class NxLogoComponent {

}
