import { Injectable, Injector }                       from '@angular/core';
import { TranslateService }                           from '@ngx-translate/core';
import { Observable, BehaviorSubject }                from 'rxjs';
import { HttpClient }                                 from '@angular/common/http';

import * as moment                                    from 'moment';
import * as FileSaver                                 from 'file-saver';

// Models
import { NxExport, NxExportConst, NxExportInterface } from '../../common/utils/classes/nx-export';
import { NxObject }                                   from '../../common/utils';
//Services
import { NxPdfService }                               from './nx-pdf.service';
import { NxMessageService }                           from '../../common/components/nx-message/nx-message.service';
import { NxCommonService }                            from './nx-common.service';
//Pipes
import { NxValueParserPipe }                          from '../../common/pipes/nx-value-parser.pipe';
import { NxTableOptions }                             from '../../common/components/nx-table/models';


//declare var alasql: any;

var alasql = require('alasql');

@Injectable()
export class NxExportService extends NxExport {

  private context: NxExportInterface;

  constructor(protected translate: TranslateService, protected pdfService: NxPdfService, protected nxValueParserPipe: NxValueParserPipe, 
              protected message  : NxMessageService, protected http      : HttpClient,   private commonService      : NxCommonService,
              protected injector : Injector) {
    
    super(translate, pdfService, nxValueParserPipe, message);
  }

  subject: BehaviorSubject<NxObject> = new BehaviorSubject<NxObject>(null);

  get onComplete() {

    return this.subject.asObservable();
  } 

  /**
   * 
   * @param type 
   * @param data 
   * @param options 
   */
  exports(type: string, data: any, options: NxObject): Observable<NxObject> {

    if (!data || !data.length)
      return;

    this.setContext(options);  
    
    switch (type) {
      case NxExportConst.types.csv:
        return this.exportCsv(data, options);
      case NxExportConst.types.pdf:
        return this.exportPdf(data, options);
      case NxExportConst.types.xls:
        return this.exportXls(data, options);
      default:
        return;
    }
  }

  /**
   * 
   * @param data 
   * @param options 
   */
  private exportCsv(data: any, options: NxObject): Observable<NxObject> {
      
    this.message.showInfo('common.export.loading', null, { disableTimeOut: true });

    let dataExport = this.context.getCsv(data, options);

    let name = (options.exports.title) ? `${options.exports.title}_${moment().format('DD-MM-YYYY_HH-mm')}` : moment().format('DD-MM-YYYY_HH-mm');

    alasql(`SELECT * INTO CSV("${name}", {headers:true}) FROM ?`, [dataExport], (res, err) => {

      if (err)
        return;

      this.subject.next({ complete: true });
      this.message.clear();
    });

    return this.onComplete;
  }

  /**
   * 
   * @param data 
   * @param options 
   */
  private exportXls(data: any, options: NxObject): Observable<NxObject> {
   
    this.message.showInfo('common.export.loading', null, { disableTimeOut: true });

    let dataExport = this.context.getXls(data, options);

    let name = (options.exports.title) ? `${options.exports.title}_${moment().format('DD-MM-YYYY_HH-mm')}` : moment().format('DD-MM-YYYY_HH-mm');

    dataExport.xlsx.writeBuffer().then(buffer => {
      
      FileSaver.saveAs(new Blob([buffer]), `${name}.xlsx`);

      this.subject.next({ complete: true });
      this.message.clear();
    });


    return this.onComplete;
  }

  /**
   * 
   * @param data 
   * @param options 
   */
  private exportPdf(data: any, options: NxObject): Observable<NxObject> {
     
    this.context.getPdf(data, options);

    return this.context.onComplete;
  }

  /**
   * 
   * @param url 
   * @param callback 
   */
  convertFileToDataURL(url, callback) {

    return this.pdfService.convertFileToDataURL(url, callback);
  }

  /**
   * 
   * @param options 
   */
   exportAll(options: NxTableOptions) {

    this.message.showInfo('common.export.loading', null, { disableTimeOut: true });

    let cols = [];

    options.columns.forEach(el => {
      
      // if (!el.choosable || (el.choosable && el.choosable.value))
      cols.push({ key: el.key, label: this.translate.instant(el.label), type: el.type, format: el.format });
    });

    let opt = {
      entity: options.model,
      cols  : cols,
      method: options['method'],
      params: options['params']
    };

    return this.http.post(`${this.commonService.getBaseUrl()}/api/rest/exportData`, opt, { responseType: 'blob' }).subscribe((res) => {

      if (!res)
        return;

      FileSaver.saveAs(res, `${moment().format('DD-MM-YYYY_HH-mm')}.csv`);
      this.message.clear();
    });
  }

  /**
   * 
   * @param options 
   */
  private setContext(options) {

    if (!options)
      return;

    this.context = (options.exports.service) ? this.injector.get(options.exports.service) : this;
  }
  
  // /**
  //  * 
  //  * @param url 
  //  * @param options 
  //  * @param name 
  //  */
  // downloadZip(url: string, options: NxObject, name?: string): void {

  //   this.message.showInfo('common.export.loading', null, { disableTimeOut: true });

  //   this.http.post(url, options, { responseType: 'blob' }).subscribe((res) => {

  //     if (!res)
  //       return;

  //     FileSaver.saveAs(res, `${name || moment().format('DD-MM-YYYY_HH-mm')}.zip`);
  //     this.message.clear();
  //   });
  // }


  /**
   * 
   * @param url 
   * @param options 
   * @param name 
   */
  downloadXlsx(url: string, options: NxObject, name?: string): void {

    this.message.showInfo('common.export.loading', null, { disableTimeOut: true });

    this.http.post(url, options, { responseType: 'blob' }).subscribe((res) => {

      if (!res)
        return;

      FileSaver.saveAs(res, `${name || moment().format('DD-MM-YYYY_HH-mm')}.xlsx`);
      this.message.clear();
    });
  }
}
