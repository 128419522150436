import { ArrayCodedItem } from '../../core/graphql/nx-graphql-types';
import { NxObject } from 'src/app/common/utils';
import { runInThisContext } from 'vm';

type UserProfile = {
  firstname: string;
  lastname : string;
};

export interface UserInterface {
  _id?          : string;
  username?     : string;
  password?     : string;
  profile?      : UserProfile;
  email?        : string;
  createdAt?    : Date;
  updatedAt?    : Date;
  organizations?: ArrayCodedItem[];
  active?       : boolean;
  functs?       : string[];
  roles?        : string[];
  config?       : NxObject;
  deleted?      : boolean;
  deletedAt?    : Date;
  operatorId?   : number;
  operatorData? : NxObject;
}

export class User implements UserInterface {
  _id?          : string;
  services?     : any;
  username?     : string;
  password?     : string;
  profile?      : UserProfile;
  email?        : string;
  createdAt?    : Date;
  updatedAt?    : Date;
  organizations?: ArrayCodedItem[];
  active?       : boolean;
  functs?       : string[];
  roles?        : string[];
  config?       : NxObject;
  deleted?      : boolean;
  deletedAt?    : Date;
  operatorId?   : number;
  operatorData? : NxObject;

  get fullName() { return `${this.profile.lastname} ${this.profile.firstname}` }

  set newPassword(value: string) { this.password = value; }
  get newPassword()              { return this.password;  }
  
  set firstname(firstname: string) { this.profile.firstname = (firstname); }
  set lastname(lastname: string)   { this.profile.lastname = (lastname); }

  constructor(obj?: any) {
    this._id           = (obj && obj._id)           || null;
    this.username      = (obj && obj.username)      || null;
    this.password      = (obj && obj.password)      || null;
    this.profile       = (obj && obj.profile)       || {};
    this.functs        = (obj && obj.functs)        || [];
    this.roles         = (obj && obj.roles)         || [];
    this.active        = (obj && obj.active)        || false;
    this.organizations = (obj && obj.organizations) || []; 
    this.config        = (obj && obj.config)        || {};
    this.deleted       = (obj && obj.deleted)       || false;
    this.operatorId    = (obj && obj.operatorId)    || null;
    this.operatorData  = (obj && obj.operatorData)  || {}; 
  }

  /**
   * Check if user has at least one of proposed roles
   * 
   * @param roles 
   */
  hasRoles(roles: string | string[]): boolean {

    if (!roles || !roles.length)
      return true;

    if (!this.roles)
      return false;

    if (!Array.isArray(roles))
      return (this.roles.indexOf(roles) !== -1);

    if (roles.length === 1)
      return !!this.roles.find(r => { return r === roles[0]; });

    return !!this.roles.find(r => { return roles.indexOf(r) !== -1; });
  }

  /**
   * Check if user has at least one of proposed functs
   * 
   * @param roles 
   */
  hasFunctions(functs: string | string[]): boolean {

    if (!functs || !functs.length)
      return true;

    if (!this.functs)
      return false;

    if (!Array.isArray(functs))
      return (this.functs.indexOf(functs) !== -1);

    if (functs.length === 1)
      return !!this.functs.find(r => { return r === functs[0]; });

    return !!this.functs.find(r => { return functs.indexOf(r) !== -1; });
  }
};
