import { Directive, HostBinding, Input } from '@angular/core';

import { NxBarComponent } from './nx-bar.component';

// todo: progress element conflict with bootstrap.css
// todo: need hack: replace host element with div
@Directive({ selector: 'nxProgress, [nxProgress]' })
export class NxProgressDirective {
	
    /** if `true` changing value of progress bar will be animated (note: not supported by Bootstrap 4) */
  @Input() public animate: boolean;

  /** maximum total value of progress element */
  @HostBinding('attr.max')
  @Input()
  public get max(): number {
    return this._max;
  }

  public set max(v: number) {
  this._max = v;
    this.bars.forEach((bar: NxBarComponent) => {
        bar.recalculatePercentage();
    });
  }

  @HostBinding('class.progress') public addClass = true;

  public bars: any[] = [];

  protected _max = 100;

  public addBar(bar: NxBarComponent): void {
    if (!this.animate) {
    bar.transition = 'none';
    }
    this.bars.push(bar);
  }

  public removeBar(bar: NxBarComponent): void {
    this.bars.splice(this.bars.indexOf(bar), 1);
  }
}
