import { Component, OnInit, OnDestroy, Input, ViewChild, EventEmitter }  from '@angular/core';
import { ComponentFactoryResolver, QueryList, ViewChildren, ElementRef } from '@angular/core';
import { ChangeDetectorRef, ViewContainerRef, Type, ComponentRef }       from '@angular/core';
import { Subscription }                                                  from 'rxjs';

// Models
import { uuid }                                                          from '../../../utils';
import { NxModalButton, NxModalBodyComponent, NxModalComponentOptions }  from '../models';
import { NxModalItem, NxModalOptions, NxModalType, NxModalTabOptions }   from '../models';
// Directives
import { NxModalContentDirective }                                       from '../nx-modal-content.directive';
// Services
import { NxModalStackService }                                           from '../nx-modal-stack.service';
import { NxModalComponent } from '../nx-modal.component';
import { MOBILE_MODAL_ID } from '../models';

@Component({
  selector   : 'nx-mobile-modal',
  templateUrl: './nx-mobile-modal.component.html',
  styleUrls  : ['./nx-mobile-modal.component.scss']
})
export class NxMobileModalComponent extends NxModalComponent implements OnInit, OnDestroy  {
  
  id: string = MOBILE_MODAL_ID;
    
  hasDialogScrollbar : boolean = false;
  hasContentScrollbar: boolean = false;
  options            : NxModalOptions;
  moduleCFR          : ComponentFactoryResolver;
  item               : NxModalItem;
  componentRefs      : ComponentRef<any>[];
  
  @ViewChildren(NxModalContentDirective) protected contentHosts: QueryList<NxModalContentDirective>;
  @ViewChild('nxBaseModal')              protected modalComponent;
  
  constructor(protected modalStackService       : NxModalStackService, 
              protected componentFactoryResolver: ComponentFactoryResolver,
              protected changeDetectionRef      : ChangeDetectorRef,
              protected elementRef              : ElementRef) { 
                super(modalStackService,componentFactoryResolver,changeDetectionRef,elementRef);
              }

}
