import {Component} from '@angular/core';
import {NxSideNavAccordionItemComponent} from './nx-side-nav-accordion-item';

@Component({
  //exportAs: 'sbItemHead',
  selector: 'nx-side-nav-accordion-item-head',
  templateUrl: 'nx-side-nav-accordion-item.head.html'
})
export class NxSideNavAccordionItemHeadComponent {

  constructor(private sbItem: NxSideNavAccordionItemComponent) {}

  toggleClick(event: any) {
    event.preventDefault();
    this.sbItem.collapsed = !this.sbItem.collapsed;
    this.sbItem.toggle(this.sbItem.collapsed);
  }
}
