// Models
import { NxValueType }      from '../../../models';
import { NxBaseField }      from './nx-base-field';
import { NxFormInputTypes } from './nx-form.enums';

export class NxTextboxField extends NxBaseField<string | number> {

  controlType = NxFormInputTypes.TEXT;

  type             : NxValueType;
  maxLength        : number;
  acceptWhitespaces: boolean;

  constructor(options: {} = {}) {

    super(options);
    this.type              = options['type'] || 'text';
    this.maxLength         = options['maxLength'];
    this.acceptWhitespaces = options['acceptWhitespaces'];
  }

  manageValue(value: any): any {

    this.value = this.type === 'number' ? parseFloat(value) : value;
    return this.value;
  }

}
