export enum NxDatePeriod {
  WEEK          = 'week',
  MONTH         = 'month',
  QUARTER_1     = 'quarter1',
  QUARTER_2     = 'quarter2',
  QUARTER_3     = 'quarter3',
  QUARTER_4     = 'quarter4',
  SEMESTER_1    = 'semester1',
  SEMESTER_2    = 'semester2',
  YEAR          = 'year',
  CURR_WEEK     = 'currweek',
  CURR_MONTH    = 'currmonth',
  CURR_QUARTER  = 'currquarter',
  CURR_SEMESTER = 'currsemester',
  CURR_YEAR     = 'curryear',
  LAST_WEEK     = 'lastweek',
  LAST_MONTH    = 'lastmonth',
  LAST_QUARTER  = 'lastquarter',
  LAST_SEMESTER = 'lastsemester',
  LAST_YEAR     = 'lastyear'
}