import { Component, ContentChild, Input, OnInit } from '@angular/core';
import { ElementRef, Renderer2 }                  from '@angular/core';

// Models
import { NxObject }                               from '../../../common/utils';
import { NxAccordionConfig }                      from './nx-accordion-config';
// Components
import { NxAccordionItemBodyComponent }           from './nx-accordion-item-body.component';
// Services
import { NxAccordionService }                     from './nx-accordion.service';

@Component({
  selector   : 'nx-accordion-item',
  templateUrl: './nx-accordion-item.component.html'
})
export class NxAccordionItemComponent implements OnInit {

  @Input() collapsed  : boolean = true;
  @Input() customClass: string;
  
  @ContentChild(NxAccordionItemBodyComponent) body: NxAccordionItemBodyComponent;
  
  private accordion: any;

  constructor(protected renderer        : Renderer2, 
              protected element         : ElementRef,
              protected accordionService: NxAccordionService) {
	  
    this.accordion = NxAccordionConfig.serviceInstance;
  }

  ngOnInit() {
    
    if (this.body) {

      this.body.init(this.collapsed);
      
      if (this.collapsed) {
        
        this.renderer.addClass(this.body.bodyEl.nativeElement.parentElement.parentElement, 'transparent');
        this.renderer.addClass(this.body.bodyEl.nativeElement.parentElement.parentElement, 'z-depth-0');      

        let btnsElem = this.body.bodyEl.nativeElement.parentElement.previousElementSibling.getElementsByClassName('card-buttons')[0];

        if (btnsElem) {

          this.renderer.setStyle(btnsElem, 'opacity',    0);
          this.renderer.setStyle(btnsElem, 'visibility', 'hidden');
        }
      }

      this.accordionService.animationStateChange$.subscribe(value => this.update(value));
    }
  }
  
  /**
   * 
   * @param collapsed 
   */
  toggle(collapsed: boolean): void {
	  
    this.accordion.didItemToggled(this);
    this.applyToggle(collapsed);
  }

  /**
   * 
   * @param collapsed 
   */
  applyToggle(collapsed: boolean): void {
	  
    if (this.body) {
    	
      this.collapsed = collapsed;
      this.body.toggle(collapsed);
    }
  }
  
  /**
   * 
   * @param animationState 
   */
  update(animationState: NxObject): void {

    if (animationState.state === 'done' && animationState.event.fromState === 'expanded') {
    	
      this.renderer.addClass(animationState.event.element.parentElement.parentElement, 'transparent');
      this.renderer.addClass(animationState.event.element.parentElement.parentElement, 'z-depth-0');
      
      this.renderer.removeClass(animationState.event.element.parentElement.previousElementSibling.getElementsByClassName('card-buttons')[0], 'nxFadeIn');
      this.renderer.addClass(animationState.event.element.parentElement.previousElementSibling.getElementsByClassName('card-buttons')[0], 'nxFadeOut');
    }
    
    else if (animationState.state === 'start' && animationState.event.fromState === 'collapsed') {
    	
      this.renderer.removeClass(animationState.event.element.parentElement.parentElement, 'transparent');
      this.renderer.removeClass(animationState.event.element.parentElement.parentElement, 'z-depth-0');
      
      this.renderer.removeClass(animationState.event.element.parentElement.previousElementSibling.getElementsByClassName('card-buttons')[0], 'nxFadeOut');
      this.renderer.addClass(animationState.event.element.parentElement.previousElementSibling.getElementsByClassName('card-buttons')[0], 'nxFadeIn');
    }
  }
}
