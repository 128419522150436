import { BehaviorSubject, Observable }  from 'rxjs';
import * as moment                      from 'moment';
import * as Excel                       from "exceljs/dist/exceljs.min.js"

// Models
import { NxTableOptions }               from '../../../common/components/nx-table/models';
import { getAttr }                      from '../functs';
import { NxObject }                     from './nx-generics';
// Services
import { NxMessageService }             from '../../../common/components/nx-message/nx-message.service';


export interface NxExportInterface {

  getPdf     : Function;
  buildPdf   : Function;
  getCsv     : Function;
  getXls     : Function;
  onComplete?: Observable<NxObject>;
}

export const NxExportConst = {

  images: {
    logo: {
      key: 'logo',
      url: 'assets/images/logo.png'
    }
  },
  types: {
    pdf : 'pdf',
    xls : 'xls',
    json: 'json',
    csv : 'csv'
  }
};

export class NxExport implements NxExportInterface {

  protected translate        : any;
  protected pdfService       : any;
  protected nxValueParserPipe: any;
  protected message          : any;

  constructor(translate, pdfService, nxValueParserPipe, message: NxMessageService) {

    this.translate            = translate;
    this.pdfService           = pdfService;
    this.nxValueParserPipe    = nxValueParserPipe
    this.message              = message;
  }

  subject: BehaviorSubject<NxObject> = new BehaviorSubject<NxObject>(null);

  get onComplete() {

    return this.subject.asObservable();
  } 

  /**
   * 
   * @param data 
   * @param options 
   */
  getPdf(data, options): void {
    
    this.message.showInfo('common.exports.loading', null, { disableTimeOut: true });

    let imgs   = [ NxExportConst.images.logo ];
    let images = {};

    imgs.forEach((el) => {
     
      this.convertFileToDataURL(el.url, (res) => {

        images[el.key] = res;

        if (Object.keys(images).length === imgs.length) {

          let name = (options.exports.title) ? `${options.exports.title}_${moment().format('DD-MM-YYYY_HH-mm')}` : moment().format('DD-MM-YYYY_HH-mm');

          this.pdfService.download(this.buildPdf(data, options, images), name, () => {

            this.message.clear();
            this.subject.next({ complete: true });
          });
        }
      });
    });
  }

  /**
   * 
   * @param data 
   * @param options 
   */
  buildPdf(data: any, options: NxTableOptions, images): any {
    
    let header = [];
    let cols   = [];
    let widths = [];
    let body   = [ header ];
    let width  = (100 / options.columns.length).toString();

    options.columns.forEach((col) => {

      header.push({ text: this.translate.instant(col.label), style: 'tableHeader' });

      widths.push(width + '%'); 
    });

    data.forEach(rowData => {
      
      let row = [];

      options.columns.forEach((col) => {        
        
        row.push({ text: this.getValue(getAttr(rowData, col.key), col) || '-', style: 'tableValue' }); 
      });

      body.push(row);
    });

    var docDefinition = {
      footer         : this.getPdfFooter(options.exports && options.exports.title),
      pageMargins    : [20, 80, 20, 40],
      pageSize       : "A4",
      pageOrientation: (options.columns.length > 5) ? 'landscape' : 'portrait',
      header         : this.getPdfHeader(),
      content: [
        { text : (options.exports.title) || '-', style: 'header' },
        { table: { widths: widths, headerRows: 1, body: body } }
      ],
      images: images,
      styles: this.getPdfStyles()
    };

    return docDefinition;
  }

  /**
   * 
   * @param data 
   * @param options 
   */
  getCsv(data: any, options: NxObject): any {
    
    let dataExports = [];

    data.forEach(rowData => {
      
      let cols = {};

      options.columns.forEach((col) => {

        cols[this.translate.instant(col.label)] = this.getValue(getAttr(rowData, col.key), col) || '-';
      });

      dataExports.push(cols);
    });

    return dataExports;
  }

  /**
   * 
   * @param data 
   * @param options 
   */
  getXls(data: any, options: NxObject): any {
    
    let workbook  = new Excel.Workbook()
    let worksheet = workbook.addWorksheet('1', { state: 'visible' });

    let columns = []; 

    var fields = {};

    options.columns.forEach((col) => {
  
      columns.push({ header: this.translate.instant(col.label), key: col.key });
      fields[col.key] = col;
    });

    worksheet.columns = columns;

    data.forEach(rowData => {
    
      let value = {};

      for (let key in fields) {

        let attr   = getAttr(rowData, key);
        value[key] = this.getValue(attr, key) || '-';
      }

      worksheet.addRow(value);
    });

    return workbook;
  }

  /**
   * 
   * @param ob 
   */
  private flattenObject(ob) {

    var toReturn = {};

    for (var i in ob) {
      if (!ob.hasOwnProperty(i)) continue;

      if ((typeof ob[i]) == 'object') {
        var flatObject = this.flattenObject(ob[i]);
        for (var x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) continue;

          toReturn[i + '.' + x] = flatObject[x];
        }
      } else {
        toReturn[i] = ob[i];
      }
    }
    return toReturn;
  };

  /**
   * 
   */
  private getPdfHeader(): NxObject[] {

    return [
      {
        margin: 8,
        columns: [
          {
            image: 'logo',
            alignment: 'left',
            width: 100,
            margin: [0, 10, 0, 0]
          },
          [
            {
              columns: [{ text: this.translate.instant('app.splittedTitle.first'), margin: [0, 13, 0, 0], fontSize: 9 }]
            },
            {
              columns: [{ text: this.translate.instant('app.splittedTitle.second'), fontSize: 9 }]
            },
            {
              columns: [{ text: this.translate.instant('app.splittedTitle.third'), fontSize: 9 }]
            }
          ]
        ]
      },
      { canvas: [{ type: 'line', x1: 5, y1: 5, x2: 595 - 5, y2: 5, lineWidth: 0.1 }] }
    ];
  }

  /**
  * 
  * @param title 
  */
  private getPdfFooter(title): Function {

    return function (currentPage, pageCount) {

      return [
        { canvas: [{ type: 'line', x1: 5, y1: 5, x2: 595 - 5, y2: 5, lineWidth: 0.1 }] },
        {
          columns: [
            {
              text     : (title) ? title + '   |   ' + moment().format('DD-MM-YYYY') : moment().format('DD-MM-YYYY'),
              margin   : [15, 6, 0, 0],
              fontSize : 8,
              alignment: 'left'
            },
            {
              text     : currentPage.toString() + ' di ' + pageCount,
              margin   : [0, 6, 35, 5],
              fontSize : 8,
              alignment: 'right'
            }
          ],
          margin: [ 10, 0 ]
        }
      ]
    };
  }

  /**
   * 
   */
  private getPdfStyles(): NxObject {

    return {
      header: {
        alignment: 'center',
        fontSize : 18,
        bold     : true,
        margin   : [0, 0, 0, 20]
      },
      table: {
        margin: [0, 5, 0, 15]
      },
      tableHeader: {
        bold     : true,
        fontSize : 9,
        color    : 'black',
        fillColor: '#eceff1'
      },
      tableValue: {
        fontSize: 8
      },
    }
  }

  /**
  * 
  * @param url 
  * @param callback 
  */
  convertFileToDataURL(url, callback): void {

    var xhr = new XMLHttpRequest();

    xhr.onload = () => {

      var reader = new FileReader();

      reader.onloadend = () => {

        callback(reader.result);
      }

      reader.readAsDataURL(xhr.response);
    };

    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  /**
   * 
   */
  getValue(value: any, col): any {
  
    return this.nxValueParserPipe.transform(value, col.type);
  }
}