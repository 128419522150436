import { Injectable }          from '@angular/core';
import { Subject, Observable } from 'rxjs';

// Models
import { NxObject }            from '../../../common/utils';

@Injectable() 
export class NxAccordionService {
  
  animationState      : NxObject;
  animationStateChange: Subject<NxObject> = new Subject<NxObject>();

  // prevent multiple concurrent subscriptions
  get animationStateChange$(): Observable<NxObject> { return this.animationStateChange.asObservable(); }

  constructor() {
  
    this.animationStateChange$.subscribe((value) => { this.animationState = value; });
  }

  /**
   * 
   * @param value 
   */
  changeAnimationState(value: NxObject) {
  
    this.animationStateChange.next(value);
  }
}
