// Models
import { NxBaseField }      from './nx-base-field';
import { NxFormInputTypes } from './nx-form.enums';

export class NxSliderField extends NxBaseField<string> {

  controlType = NxFormInputTypes.SLIDER;

  min: number;
  max: number;

  constructor(options: {} = {}) {

    super(options);

    this.min = options['min'];
    this.max = options['max'];
  }
}