import { NgModule }                         from '@angular/core';
import { CommonModule }                     from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule }                  from '@ngx-translate/core';
import { ScrollbarModule }                  from 'ngx-scrollbar';

// Modules
import { NxFormDirectivesModule }           from 'src/app/common/directives/form/nx-form-directives.module';
import { NxTooltipModule }                  from 'src/app/common/directives/nx-tooltip/nx-tooltip.module';
import { NxDropdownModule }                 from 'src/app/common/directives/nx-dropdown/nx-dropdown.module';
import { NxBaseModalModule }                from 'src/app/common/components/nx-base-modal/nx-base-modal.module';

@NgModule({
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollbarModule,
    TranslateModule,
    NxFormDirectivesModule,
    NxTooltipModule,
    NxDropdownModule,
    NxBaseModalModule
  ]
})
export class SharedBaseModule { }
