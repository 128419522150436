import { Injectable }                                           from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { HttpResponse, HttpErrorResponse }                      from '@angular/common/http';
import { Router }                                               from '@angular/router';

// Observable
import { Observable }                                           from 'rxjs';

// Observable operators
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/throw';

// Services
import { AuthService }         from '../services/auth.service';
import { NxCommonService }     from '../services/nx-common.service';
import { NxGraphQLService }    from '../graphql/services/nx-graphql.service';
import { NxNavigationService } from '../../common/components/nx-navigation/nx-navigation.service';
import { NxMessageService }    from '../../common/components/nx-message/nx-message.service';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class NxAuthInterceptor implements HttpInterceptor {

  constructor(private router    : Router,
              private auth      : AuthService,
              private graphql   : NxGraphQLService,
              private message   : NxMessageService,
              private navigation: NxNavigationService,
              private common    : NxCommonService) { 

    this.graphql.httpError$.subscribe(err => this.handleError(err));
  }

  /**
   * Interceptor method
   *
   * @returns {Observable<HttpEvent>}
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let baseUrl = this.common.getBaseUrl();

    if (!baseUrl) {

      this.navigation.sendToLogin();
      return Observable.empty();
    }

    // if not secured url pass through
    if (!req.url.includes(baseUrl) && !req.url.includes('graphql'))
      return next.handle(req);

    // login api -> change url and send request
    if (req.url === `${baseUrl}/login`) {

      // replace api url because we use the sessin api as login api
      // to replace with new auth mechanism (jwt, oauth2)
      const loginReq = req.clone({ url: req.url.replace('login', 'session') });

      return next.handle(loginReq);
    }

    // Get the auth token from the service.
    //const authToken = this.auth.authToken;

    // if token does not exists send to login and reset request
    // if (!authToken) {
    //
    //   this.navigation     .sendToLogin();
    //   return Observable.empty();
    // }

    // update and send request handling error if needed
    return next.handle(this.addToken(req, this.auth.authToken)).catch(error => {

      return this.handleError(error);
    });
  }

  /**
   * Clone the request and replace the original headers with
   * cloned headers, updated with the authorization.
   *
   * @returns {HttpRequest<any>} new request with Authorization
   */
  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {

    return req.clone({ setHeaders: { Authorization: `Bearer ${token}` }})
  }

  /**
   * Handle response error
   */
  handleError(error) {

    if (!(error instanceof HttpErrorResponse))
      return Observable.throw(error);

    switch ((<HttpErrorResponse>error).status) {

      // Bad Request
      case 400:
        // manage bad request
        break;

      // Unauthorized
      case 401:
        //this.message.showError('Utente non autenticato');
        this.navigation.sendToLogin();
        break;

      // Forbidden.
      // The server understood the request, but is refusing to fulfill it.
      case 403:
        this.navigation.sendToError(403);
        //$log.debug( "error 403" + response.data.message );
        break;

      // not found / login error
      // The server has not found anything matching the Request-URI.
      // No indication is given of whether the condition is temporary or permanent
      case 404:
        this.navigation.sendToError(404);
        break;

      // Not acceptable.
      // The resource identified by the request is only capable of generating
      // response entities which have content characteristics not acceptable
      // according to the accept headers sent in the request.
      case 406:
        break;

      // Internal error.
      case 500:
        break;

      // Service Unavailable - Temp error
      case 503:
        this.navigation.sendToError(503);
        break;

      default:
        break;
    }

    return Observable.throw(error);
  }
}
