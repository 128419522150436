import {NgModule}              from '@angular/core';
import {CommonModule}          from '@angular/common';
import { NxLinksComponent }    from './nx-links.component';
import { NxLogoComponent }     from './nx-logo.component';
import { NxNavbarService }     from './nx-navbar.service';
import { NxNavbarComponent}    from './nx-navbar.component';
import { NxNavlinksComponent } from './nx-navlinks.component';

@NgModule({
  imports     : [ CommonModule ],
  declarations: [ NxNavbarComponent, NxLinksComponent, NxLogoComponent, NxNavlinksComponent ],
  exports     : [ NxNavbarComponent, NxLinksComponent , NxLogoComponent, NxNavlinksComponent ],
  providers   : [ NxNavbarService ]
})
export class NxNavbarModule {}
