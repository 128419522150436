import { FileXmlReader } from './file-xml-reader';
import { NxFile }        from './nx-file';
import { NxObject }      from '.';

export class NxFileXmlReader extends FileXmlReader {

  private _nxFile: NxFile;

  /**
   * 
   * @param file 
   */
  readNx(nxFile: NxFile, options: NxObject = {}) {

    this._nxFile = nxFile;

    // clean file
    delete this._nxFile.data.items;
    delete this._nxFile.data.type;
    
    this.read(nxFile.file, options.itemTag);
  }

  /**
   * 
   * @param item 
   */
  protected getEventData(item: NxObject): NxObject {

    if (!this._nxFile.data.items)
      this._nxFile.data.items = [];

    this._nxFile.data.items.push(item);

    return { nxFileId: this._nxFile.id, item: item };
  }
}