import { BaseEmitter } from './base-emitter';
import { NxObject }    from '.';

interface FileLineReaderOptions {
  chunkSize?: number;
  debug?    : boolean;
}

export const FileLineReaderEvents = {
  readLine: 'line',
  done    : 'done'
};

export class FileLineReader extends BaseEmitter {

  canRead           : boolean;
  
  private _file     : File;
  private _reader   : FileReader;

  private debug     : boolean = false;
  private fileLength: number;
  private chunk     : string;
  private chunkSize : number;
  private start     : number;
  private nlStart   : number;
  private lines     : string[];

  set file(file: File) {

    this._file      = file;
    this.fileLength = file.size;
    this.start      = 0;
    this.nlStart    = 0;
    this.chunk      = '';
    this.lines      = [];
    this.canRead    = true;
  }

  private set reader(reader: FileReader) {

    this._reader = reader;
    this._reader.onload = () => this.onChunkRead();
  }

  private get reader() {

    return this._reader;
  }

  constructor(options?: FileLineReaderOptions) {

    super();

    this.debug     = options && options.debug;
    this.chunkSize = (options && options.chunkSize) || 1024;
    this.reader    = new FileReader();
  }

  /**
   * 
   * @param file 
   */
  read(file: File) {

    this.file = file;
    this.readChunk();
  }

  /**
   * 
   * @param line 
   */
  protected getEventData(line: string): NxObject {

    return { line };
  }

  /**
   * 
   */
  private hasMoreData() {
    
    return this.start <= this._file.size;
  };

  /**
   * 
   */
  private onChunkRead() {

    this.chunk += this.reader.result;
    
    // If the processed text contains a newline character
    if (/\r|\n/.test(this.chunk)) {

      //Split the text into an array of lines
      this.lines = this.chunk.match(/[^\r\n]+/g) || [];

      // If there is still more data to read, save the last line, as it may be incomplete
      if (this.hasMoreData()) {
        // If the loaded chunk ends with a newline character then the last line
        // is complete and we don't need to store it

        if (!this.lines || this.chunk[this.chunk.length - 1] === '\n')
          this.chunk = '';
        else if (this.lines.length > 2) {

          //this.chunk = this.lines[1];
          this.chunk = '';
          this.start -= this.chunkSize - this.lines[0].length;
        }
        else
          this.chunk = this.lines.pop();
      }
      else if (this.lines.length > 2) {

        //this.chunk = this.lines[1];
        this.chunk  = '';
        this.start -= this.chunkSize - this.lines[0].length;
      }
      else
        this.chunk = '';
      
      this.step();
      return;
    }

    if (!this.chunk)
      return this.step();

    // Start another round of the read process if there is still data to read
    if (this.hasMoreData())
      return this.readChunk();

    // If there is no data left to read, but there is still data stored in 'chunk', emit it as a line
    if (this.chunk.length)
      this.emit({ type: FileLineReaderEvents.readLine, data: this.getEventData(this.chunk) });

    // if there is no data stored in 'chunk', emit the end event
    this.emit({ type: FileLineReaderEvents.done });
  }

  /**
   * 
   */
  private readChunk(): void {
  
    if (!this._file)
      return;
    
    // Extract a section of the file for reading starting at 'readPos' and
    // ending at 'readPos + chunkSize'
    var blob = this._file.slice(this.start, this.start + this.chunkSize);
  
    // Update our current read position
    this.start += this.chunkSize;

    // Read the blob as text
    //this.reader.readAsBinaryString(blob);
    this.reader.readAsText(blob);
  }

  /**
   * 
   */
  private step() {

    // If there are no lines left to emit and there is still data left to read,
    // start the read process again, otherwise, emit the 'end' event
    if (!this.lines.length) {

      if (this.hasMoreData())
        return this.readChunk();

      return this.emit({ type: FileLineReaderEvents.done });
    }

    // If we can't read, emit the 'end' event
    if (!this.canRead)
      return this.emit({ type: FileLineReaderEvents.done });
    
    // If the reading process hasn't been aborted, emit the first element of the
    // line array, and pass in '_step' for the user to call when they are ready
    // for the next line. We have to bind '_step' to 'this', otherwise it will be
    // in the wrong scope when the use calls it
    
    // fix things if chunkSize > line length
    if (this.chunkSize > 30 && this.lines[0].length < this.chunkSize) {

      this.start    -= this.chunk.length;//this.chunkSize;
      this.chunkSize = this.lines[0].length;
      this.chunk     = '';
      // Update our current read position
      //this.start    += this.chunkSize;
    }

    // this.nlStart += this.lines[0].length+1;
    // this.start    = this.nlStart;
    // this.chunk    = '';

    if (this.debug)
      console.log('line', this.lines[0]);
    
    this.emit({ type: FileLineReaderEvents.readLine, data: this.getEventData(this.lines.shift()) });
    
    //return;
    this.readChunk();
  }
}