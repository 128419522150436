import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NxSideNavComponent } from './nx-side-nav.component';

@NgModule({
  declarations: [
	  NxSideNavComponent,
  ],
  exports: [
	  NxSideNavComponent
  ],
  imports: [
    CommonModule,
  ]
})
export class NxSideNavModule { }
