export enum NxFormInputTypes {
  SELECT          = 'select',
  TEXT            = 'textbox',
  TEXTAREA        = 'textarea',
  CHECKBOX        = 'checkbox',
  RADIO           = 'radio',
  SWITCH          = 'switch',
  DATEPICKER      = 'datepicker',
  TIMEPICKER      = 'timepicker',
  AUTOCOMPLETE    = 'autocomplete',
  PASSWORD        = 'password',
  SLIDER          = 'slider',
  LABEL           = 'label',
  PASSWORDCONFIRM = 'passwordConfirm',
  BINDER          = 'binder'
}

export enum NxFormInputTextTypes {
  TEXT   = 'text',
  NUMBER = 'number'
}
