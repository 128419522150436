// Models
import { NxBaseField }      from './nx-base-field';
import { NxFormInputTypes } from './nx-form.enums';

export class NxAutocompleteField extends NxBaseField<string> {

  controlType = NxFormInputTypes.AUTOCOMPLETE;
  
  items       : any[];
  labelField  : string;
  dataSource  : Function;
  itemSelected: Function;

  constructor(options: {} = {}) {

    super(options);

    this.dataSource   = options['dataSource'];
    this.itemSelected = options['itemSelected'];
  }
}