import { NgModule }                     from '@angular/core';

// Modules
import { SharedBaseModule }             from '../../../shared/shared-base.module';
// Components
import { NxSideNavAccordionComponent }         from './nx-side-nav-accordion';
import { NxSideNavAccordionItemComponent }     from './nx-side-nav-accordion-item';
import { NxSideNavAccordionItemHeadComponent } from './nx-side-nav-accordion-item.head';
import { NxSideNavAccordionItemBodyComponent } from './nx-side-nav-accordion-item.body';

@NgModule({
  imports     : [ SharedBaseModule ],
  exports     : [ NxSideNavAccordionComponent, NxSideNavAccordionItemComponent, NxSideNavAccordionItemHeadComponent, NxSideNavAccordionItemBodyComponent ],
  declarations: [ NxSideNavAccordionComponent, NxSideNavAccordionItemComponent, NxSideNavAccordionItemHeadComponent, NxSideNavAccordionItemBodyComponent ]
})
export class NxSideNavAccordionModule { }
