// Models
import { NxBaseField }      from './nx-base-field';
import { NxFormInputTypes } from './nx-form.enums';

export class NxSwitchField extends NxBaseField<string> {

  controlType = NxFormInputTypes.SWITCH;
  
  onLabel : string;
  offLabel: string;

  constructor(options: {} = {}) {

    super(options);
    this.onLabel  = options['onLabel']  || ''; 
    this.offLabel = options['offLabel'] || ''; 
  }
}