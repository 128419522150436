import { NgModule }           from '@angular/core';
// Modules
import { NxNavigationModule } from '../common/components/nx-navigation/nx-navigation.module';
// Models
import { NxMenuItems }        from '../common/components/nx-navigation/nx-menu-item';
import { BaseRoles }          from '../common/models';

const menu: NxMenuItems = [{ 
  name : 'admin', 
  label: 'admin.navigation.title', 
  icon : 'mdi mdi-settings-outline', 
  role : [BaseRoles.SUPER_ADMIN], 
  items: [
    { 
      id        : 'users',         
      name      : 'users',         
      icon      : 'mdi mdi-account-multiple-outline', 
      label     : 'admin.navigation.users',         
      routerLink: '/admin/users',         
      role      : [BaseRoles.SUPER_ADMIN] 
    },
    { 
      id        : 'roles',         
      name      : 'roles',         
      icon      : 'mdi mdi-label-outline',            
      label     : 'admin.navigation.roles',         
      routerLink: '/admin/roles',         
      role      : [BaseRoles.SUPER_ADMIN] 
    },
    /* { 
      id        : 'organizations', 
      name      : 'organizations', 
      icon      : 'mdi mdi-hexagon-outline',          
      label     : 'admin.navigation.organizations', 
      routerLink: '/admin/organizations', 
      role      : [BaseRoles.SUPER_ADMIN] 
    }, 
    { 
      id        : 'coded-entity', 
      name      : 'coded-entity', 
      icon      : 'mdi mdi-layers-outline',          
      label     : 'admin.navigation.lists', 
      routerLink: '/admin/lists', 
      role      : [BaseRoles.SUPER_ADMIN] 
    }*/

  ]
}];

@NgModule({
  imports  : [ NxNavigationModule.forChild(menu) ],
  exports  : [ NxNavigationModule ],
  providers: []
})
export class AdminNavigationModule { }
