import { Component }   from '@angular/core';

// Services
import { AuthService } from './core/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
//import core module per i ng-select


@Component({
  selector   : 'app-root',
  templateUrl: './app.component.html',
  styleUrls  : ['./app.component.scss']
})
export class AppComponent {

  constructor(private translate: TranslateService, private authService: AuthService) {

    this.authService.checkSession();
  }

  useLanguage(item) {
      
    this.translate.use(item);
  }
}
