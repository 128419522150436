import { Router } from '@angular/router';

export class Common {

  date : RegExp = new RegExp('^[0-9]{2}/[0-9]{2}/[0-9]{4}$');
  phone: RegExp = new RegExp('^(?:\+\d{1,3}|0\d{1,3}|00\d{1,2})?(?:\s?\(\d+\))?(?:[-\/\s.]|\d)+$');
  email: RegExp = new RegExp('^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$');

  /**
   * 
   * @param value 
   */
  coerceBooleanProperty(value: any): boolean {

    return value != null && `${value}` !== 'false';
  }
}
