import { Pipe, PipeTransform }    from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ 
  name: 'nxSafeHtml' 
})
export class NxSafeHtmlPipe implements PipeTransform {
	
  constructor(private sanitizer: DomSanitizer) { }
  
  /**
   * 
   */
  transform(value): SafeHtml {
	  
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}