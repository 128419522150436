// Models
import { NxFormFieldset, NxFormInputTypes } from '../../common/components/nx-dynamic-form/models';
import { NxTableColumn }                    from '../../common/components/nx-table/models';
import { NxValueType }                      from '../../common/models';
import { NxSelectItem }                     from '../../common/utils';

export const BASE_RET_FIELDS: string[] = ['_id', 'name', 'code', 'description'];

export const BASE_FORM_FIELDSET: NxFormFieldset[] = [
  {
    order : 1, 
    fields: [
      { key: 'code',        controlType: NxFormInputTypes.TEXT,     label: 'entity.labels.code',        required: true,   colClass: 'col-6',  order: 1 },
      { key: 'name',        controlType: NxFormInputTypes.TEXT,     label: 'entity.labels.name',        required: true,   colClass: 'col-6',  order: 2 },
      { key: 'description', controlType: NxFormInputTypes.TEXTAREA, label: 'entity.labels.description', required: false,  colClass: 'col-12', order: 3 }
    ]
  }
];

export const BASE_COLUMNS: NxTableColumn[] = [
  { id: 'code',         key: 'code',        label: 'entity.labels.code',        type: NxValueType.STRING, searchable: true,  sortable: true },
  { id: 'name',         key: 'name',        label: 'entity.labels.name',        type: NxValueType.STRING, searchable: true,  sortable: true },
  { id: 'description',  key: 'description', label: 'entity.labels.description', type: NxValueType.STRING, searchable: true }
];

