// Models
import { NxBaseField }      from './nx-base-field';
import { NxFormInputTypes } from './nx-form.enums';

export class NxLabelField extends NxBaseField<string> {

  controlType = NxFormInputTypes.LABEL;

  constructor(options: {} = {}) {

    super(options);
  }
}