import { Injectable } from '@angular/core';

import CryptoJS       from 'crypto-js';
import * as moment    from 'moment';

const a = 'temp';

@Injectable()
export class NxCryptService {

  d: string;
  //iv  = CryptoJS.lib.WordArray.create('just-a-k');

  constructor() {

    this.d = moment().format('YYYYMMDD');
  }

  /**
   * Get Key to decrypt data.
   * Use stored string in combination with current date.
   * Create stored string if needed
   *
   * @returns {string} Key used to decrypt
   */
  get k(): string {

    let key = localStorage.getItem(a);

    if (!key) {

      key = CryptoJS.lib.WordArray.random(128/8).toString();
      localStorage.setItem(a, key);
    }

    return `${key.slice(0, -8)}+${this.d}`;
  }

  /**
   * Encrypt string
   * @returns {string} Encrypted value
   */
  encrypt(text: string): string {

   return CryptoJS.AES.encrypt(text, this.k);
  }

  /**
   * Decrypt string
   * @returns {string} Decrypted value
   */
  decrypt(text: string): string {

    return CryptoJS.AES.decrypt(text, this.k).toString(CryptoJS.enc.Utf8);
  }
}
