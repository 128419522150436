
// Models
import { NxBaseField }      from './nx-base-field';
import { NxFormInputTypes } from './nx-form.enums';
import { IMyOptions }       from '../../nx-date-picker';

export class NxDatePickerField extends NxBaseField<string> {

  controlType  : NxFormInputTypes = NxFormInputTypes.DATEPICKER;

  options?     : IMyOptions;
  minDateField?: string;
  maxDateField?: string;
  placeholder? : string;
  update       : any;
  locale?      : string;
  disabled?    : boolean;  

  constructor(options: {} = {}) {
    
    super(options);

    this.disabled     = options['disabled'];
    this.minDateField = options['minDateField'];
    this.maxDateField = options['maxDateField'];
    this.options      = options['options']     || {};
    this.placeholder  = options['placeholder'] || 'common.selectedDate';
    this.update       = options['update'];
    this.locale       = options['locale'] || 'it';
  }
}