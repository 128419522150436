import { Component, OnInit, OnDestroy, Input, ViewChild, EventEmitter }  from '@angular/core';
import { ComponentFactoryResolver, QueryList, ViewChildren, ElementRef } from '@angular/core';
import { ChangeDetectorRef, ViewContainerRef, Type, ComponentRef }       from '@angular/core';
import { Subscription }                                                  from 'rxjs';

// Models
import { uuid }                                                          from '../../utils';
import { NxModalButton, NxModalBodyComponent, NxModalComponentOptions }  from './models';
import { NxModalItem, NxModalOptions, NxModalType, NxModalTabOptions }   from './models';
// Directives
import { NxModalContentDirective }                                       from './nx-modal-content.directive';
// Services
import { NxModalStackService }                                           from './nx-modal-stack.service';

@Component({
  selector   : 'nx-modal',
  templateUrl: './nx-modal.component.html',
  styleUrls  : ['./nx-modal.component.scss']
})
export class NxModalComponent implements OnInit, OnDestroy {
  
  @Input() id: string;
    
  hasDialogScrollbar : boolean = false;
  hasContentScrollbar: boolean = false;
  options            : NxModalOptions;
  moduleCFR          : ComponentFactoryResolver;
  item               : NxModalItem;
  componentRefs      : ComponentRef<any>[];
  
  @ViewChildren(NxModalContentDirective) protected contentHosts: QueryList<NxModalContentDirective>;
  @ViewChild('nxBaseModal')              protected modalComponent;
  
  private confirmSub: Subscription;
  private closeSub  : Subscription;

  constructor(protected modalStackService       : NxModalStackService, 
              protected componentFactoryResolver: ComponentFactoryResolver,
              protected changeDetectionRef      : ChangeDetectorRef,
              protected elementRef              : ElementRef) { }

  ngOnInit() {

    this.id            = this.id || uuid();
    this.options       = new NxModalOptions();
    this.componentRefs = [];

    this.modalStackService.registerModal(this);
  }

  /**
   * 
   */
  ngAfterViewInit() {
    
    this.contentHosts.changes.subscribe(t => {
      this.manageTabMode();
    });
  }

  /**
   * Unregister modal form stack service when component
   * is destroyed
   */
  ngOnDestroy() {

    if (this.confirmSub)
      this.confirmSub.unsubscribe();
    
    if (this.closeSub)
      this.closeSub.unsubscribe();

    this.modalStackService.unregisterModal(this);
  }

  /**
   * 
   */
  beforeClose(): Promise<boolean> {

    return new Promise((resolve) => {

      let promises = [];

      this.contentHosts.forEach((host, index) => {
        
        if (!this.componentRefs[index])
          return;
        
        let instance = (<NxModalBodyComponent>this.componentRefs[index].instance);
        let res = instance.beforeClose && instance.beforeClose();
        
        if (res) promises.push(res);
      });
  
      if (!promises.length)
        resolve(true);
      
      Promise.all(promises).then(res => {
        resolve(res.every(Boolean));
      });
    });
  }

  /**
   * 
   */
  beforeConfirm(): void {

    this.contentHosts.forEach((host, index) => {
    
      let instance = (<NxModalBodyComponent>this.componentRefs[index].instance);
      instance.beforeConfirm && instance.beforeConfirm();
    });
  }
  
  /**
   * 
   */
  afterOpen(): void {

    this.contentHosts.forEach((host, index) => {

      if (!this.componentRefs || !this.componentRefs.length)
        return;
    
      let instance = (<NxModalBodyComponent>this.componentRefs[index].instance);
      instance.afterOpen && instance.afterOpen();
    });
  }

  /**
   * Close modal
   */
  close(): void {

    this.beforeClose().then(res => {

      if (!res)
        return;

      if (this.options && this.options.cancel && this.options.cancel.method)
        this.options.cancel.method(this.getData());
      
      // if (this.isFullPage())
      //   this.modalStackService.togglePageContent(true);
        // this.showPageContent();

      this.modalComponent.hide();
      
      this.hasContentScrollbar = false;
    });
  }
  
  /**
   * 
   */
  confirm(componentIndex?: number): void {

    this.beforeConfirm();

    if (this.options && this.options.confirm)
      this.options.confirm.method(this.getData());
    
    if (this.isFullPage())
      this.modalStackService.togglePageContent(true);
      //this.showPageContent();
      
    this.modalComponent.hide();
  }

  /**
   * Get css class for input button
   * 
   * @param btn 
   */
  getButtonClass(btn: NxModalButton): string {

    return btn && btn.className;
  }
  
  /**
   * Get label for input button
   * 
   * @param btn 
   */
  getButtonText(btn: NxModalButton): string {
    
    return btn && btn.text;
  }

  /**
   * 
   * @param index 
   */
  getData(index: number = 0): any {

    return (<NxModalBodyComponent>this.componentRefs[0].instance).data;
  }

  /**
   * 
   */
  getDialogClass(): string {

    return `${this.options.type || NxModalType.CENTRAL_SM} ${this.options.style || ''}`;
  }

  /**
   * 
   */
  getModalClass(): string {

    let modalClass = this.options.modalClass;

    switch(this.options.type) {

      case NxModalType.FLUID_RIGHT:
        return 'right ' + modalClass;
      case NxModalType.FLUID_LEFT:
        return 'left ' + modalClass;
      case NxModalType.FLUID_BOTTOM:
      case NxModalType.FULL_PAGE:
        return 'bottom ' + modalClass;
      default: 
        return modalClass;
    }
  }
  
  /**
   * 
   */
  setHasDialogScrollbar(): void {
	  
    this.hasDialogScrollbar = (this.options.type !== NxModalType.FULL_PAGE && this.options.type !== NxModalType.FLUID_RIGHT);
  }

  /**
   * 
   */
  setHasContentScrollbar(): void {

    this.hasContentScrollbar = (this.options.type === NxModalType.FULL_PAGE || this.options.type === NxModalType.FLUID_RIGHT);
  }

  /**
   * 
   */
  showFooter(): boolean {

    return this.options.showFooter && this.hasFooter(); 
  }
  
  /**
   * Return if modal has footer
   */
  hasFooter(options?: NxModalOptions | NxModalTabOptions): boolean {

    if (!options)
      options = this.options;

    return !!(options.cancel || options.confirm || (options.buttons && options.buttons.length));
  }

  /**
   * 
   */
  disableConfirm(): boolean {

    if (!this.componentRefs || !this.componentRefs.length)
      return false;

    // return !(<NxModalBodyComponent>this.componentRefs[0].instance).isValid;

    let status = true;

    for (const el of this.componentRefs) {
      
      if ((<NxModalBodyComponent>el.instance).isValid !== undefined && (<NxModalBodyComponent>el.instance).isValid !== null)
        status = status && (<NxModalBodyComponent>el.instance).isValid;

    }

    return !status;
  }

  /**
   * Disable tab mode after modal close to reset component
   */
  onModalClose(): void {

    this.item                = null;
    this.options.tabMode     = false;
    this.hasContentScrollbar = false;
    
    this.componentRefs.forEach(cr => cr.destroy());
  }

  /**
   * 
   */
  onModalHide(): void {

    if (this.isFullPage())
      this.modalStackService.togglePageContent(true);

    if (this.options.onHide)
      this.options.onHide(this.getData());
  }

  /**
   * Open modal
   * 
   * @param moduleCFR 
   * @param item 
   */
  open(moduleCFR: ComponentFactoryResolver, item?: NxModalItem): void {

    if (item) {

      this.item          = item;
      this.moduleCFR     = moduleCFR;
      this.componentRefs = [];

      this.loadOptions(item);

      if (!item.options.tabMode)
        this.loadContent();
    }
    
    if (this.isFullPage())
      this.modalStackService.togglePageContent(false);
    
    this.setHasDialogScrollbar();
    this.setHasContentScrollbar();
    
    this.modalComponent.show();
    
    this.afterOpen();
  }
  
  /**
   * Creates and Appends new component instance to viewContainerRef
   * @param viewContainerRef 
   * @param component 
   */
  private appendContent(viewContainerRef: ViewContainerRef, component: Type<any>, componentOptions: NxModalComponentOptions): void {
    
    viewContainerRef.clear();

    let componentFactory  = this.moduleCFR.resolveComponentFactory(component);
    let componentRef      = viewContainerRef.createComponent(componentFactory);
    let componentInstance = <NxModalBodyComponent>componentRef.instance;

    this.componentRefs.push(componentRef);
    
    componentInstance.data    = (componentOptions && componentOptions.data) || {};
    componentInstance.options = componentOptions && componentOptions.options;
    
    if (componentInstance.close instanceof EventEmitter)
      this.closeSub = componentInstance.close.subscribe(() => this.close());

    if (componentInstance.confirm instanceof EventEmitter)
      this.confirmSub = componentInstance.confirm.subscribe(() => this.confirm());

    if (componentOptions && componentOptions.events) {
      
      componentOptions.events.forEach(evt => {

        if (componentInstance[evt.key] instanceof EventEmitter)
          componentInstance[evt.key].subscribe(evt.method);
      });
    }

    /**
     * manage components custom events
     */
    // if (componentOptions && componentOptions.events) {
    //   for (let eventKey in componentInstance.events) {
    //     if (!!componentInstance.events[eventKey] && componentInstance.events[eventKey] instanceof EventEmitter)
    //       componentInstance.events[eventKey].subscribe(componentOptions.events[eventKey]);
    //   }
    // }
  }

  /**
   * Injects components inside modal body
   */
  private loadContent(): void {

    if (!this.item || !this.item.component)
      return;

    if (!Array.isArray(this.item.component))
      this.item.component = [this.item.component];

    if (!Array.isArray(this.item.componentOptions))
      this.item.componentOptions = [this.item.componentOptions];

    this.appendContent(this.contentHosts.first.viewContainerRef, this.item.component[0], this.item.componentOptions[0]);
  }

  /**
   * Injects components inside tabs' bodies
   */
  private loadTabsContent(): void {

    if (!this.item || !this.item.component)
      return;
    
    if (!Array.isArray(this.item.component))
      this.item.component = [this.item.component];
    
    if (!Array.isArray(this.item.componentOptions))
      this.item.componentOptions = [this.item.componentOptions];
    
    this.contentHosts.forEach((host, index) => this.appendContent(host.viewContainerRef, this.item.component[index], this.item.componentOptions[index]));
  }

  /**
   * Update modal configuration according to passed options
   * 
   * @param item 
   */
  private loadOptions(item: NxModalItem): void {

    this.options = new NxModalOptions(item.options);
    //console.log('options', this.options);
  }

  /**
   * Load tabs after ngFor end to render to detect correctly generated children
   */
  private manageTabMode(): void {

    if (!this.options.tabMode)
      return;

    // load after ngFor end to render to detect correctly generated children
    this.loadTabsContent();

    // to prevent ExpressionChangedAfterItHasBeenCheckedError
    this.changeDetectionRef.detectChanges();
  }

  /**
   * 
   */
  private isFullPage(): boolean {

    return this.options.type === NxModalType.FULL_PAGE;
  }
}
