import { NgModule }           from '@angular/core';
// Modules
import { NxNavigationModule } from '../common/components/nx-navigation/nx-navigation.module';
// Models
import { NxMenuItems }        from '../common/components/nx-navigation/nx-menu-item';
import { BaseRoles }          from '../common/models';

const menu: NxMenuItems = [{ 
  name      : 'covidPlanner', 
  label     : 'covid.navigation.title', 
  icon      : 'mdi mdi-calendar-week', 
  role      : [BaseRoles.SUPER_ADMIN, BaseRoles.ADMIN, BaseRoles.GUEST],
  items     : [],
  routerLink: '/covidPlanner'
}];

@NgModule({
  imports  : [ NxNavigationModule.forChild(menu) ],
  exports  : [ NxNavigationModule ],
  providers: []
})
export class CovidNavigationModule { }