// Models
import { User } from './user';

export interface RoleInterface {
  _id?  : string;
  code? : string;
  name? : string;
  users?: User[];
}

export class Role implements RoleInterface {
  _id?  : string;
  code? : string;
  name? : string;
  users?: User[];

  constructor(obj?: any) {
    this._id   = (obj && obj._id)   || null;
    this.code  = (obj && obj.code)  || null;
    this.name  = (obj && obj.name)  || null;
    this.users = (obj && obj.users) || [];
  }
};
