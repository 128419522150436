import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nxFileSize'
})
export class NxFileSizePipe implements PipeTransform {

  transform(size: number, args?: any): any {

    if (isNaN(size))
      size = 0;

    if (size < 1024)
      return size + ' Bytes';

    size /= 1024;

    if (size < 1024)
      return size.toFixed(2) + ' KB';

    size /= 1024;

    if (size < 1024)
      return size.toFixed(2) + ' MB';

    size /= 1024;

    if (size < 1024)
      return size.toFixed(2) + ' GB';

    size /= 1024;
    
    return size.toFixed(2) + ' TB';
  }
}
