import { NxMessageType } from './nx-message';

export class NxMessageOptions {

  closeButton?   : boolean;
  progressBar?   : boolean;
  tapToDismiss?  : boolean;
  positionClass? : string = NxMessageType.bottomCenter;
  timeOut?       : number;
  maxOpened?     : number;
  actionText?    : string;
  actionClass?   : string;
  disableTimeOut?: boolean;
  
  constructor(options: NxMessageOptions = {}) {
	  
    this.closeButton    = options && options.closeButton    || false;
    this.progressBar    = options && options.progressBar    || false;
    this.tapToDismiss   = options && options.tapToDismiss   || false;
    this.disableTimeOut = options && options.disableTimeOut || false;
    this.positionClass  = options && options.positionClass  || NxMessageType.bottomCenter;
    this.timeOut        = options && options.timeOut        || 5000;
    this.maxOpened      = options && options.maxOpened      || 1;
    this.actionText     = options && options.actionText     || 'common.buttons.close';
    this.actionClass    = options && options.actionClass    || 'white-text';
  }
}