// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/* Configurazione locale
export const environment = {
  production: false,
  version   : 'v1.0.0',
  build     : 'xxxxxxxxxxx',
  baseUrl   : 'http://covid.surgiq.com:3000',
  api       : { rest: 'api/rest', graphql: 'api/graphql' }
};
*/

//Configurazione AWS EC2 test

export const environment = {
  production: false,
  version   : 'v1.0.0',
  build     : '20200504-1',
  baseUrl   : 'https://covid.surgiq.com',
  api       : { rest: 'api/rest', graphql: 'api/graphql' }
};
//baseUrl   : 'http://35.195.1.238:30003',



//Configurazione AWS EC2 test
/*
export const environment = {
  production: false,
  version   : 'v1.0.0',
  build     : 'xxxxxxxxxxx',
  baseUrl   : 'http://34.252.233.237:3000',
  api       : { rest: 'api/rest', graphql: 'api/graphql' }
};*/


/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
