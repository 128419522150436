import {Component, ContentChild, Input, AfterViewInit} from '@angular/core';
import {NxSideNavAccordionItemBodyComponent} from './nx-side-nav-accordion-item.body';
import {NxSideNavAccordionConfig} from './nx-side-nav-accordion.config';

@Component({
  //exportAs: 'sbItem',
  selector: 'nx-side-nav-accordion-item',
  templateUrl: 'nx-side-nav-accordion-item.html'
})
export class NxSideNavAccordionItemComponent implements AfterViewInit {

  private squeezebox: any;

  @Input() public collapsed = true;

  @ContentChild(NxSideNavAccordionItemBodyComponent) body: NxSideNavAccordionItemBodyComponent;

  constructor() {
    this.squeezebox = NxSideNavAccordionConfig.serviceInstance;
  }

  ngAfterViewInit() {
    this.body.toggle(this.collapsed);
  }
  toggle(collapsed: boolean) {
    this.squeezebox.didItemToggled(this);
    this.applyToggle(collapsed);
  }

  applyToggle(collapsed: boolean) {
    this.collapsed = collapsed;
    this.body.toggle(collapsed);
  }

}
