import { InjectionToken, Component } from '@angular/core';

export const MENU_ITEMS = new InjectionToken<NxMenuItem[][]>('MENU_ITEMS');

export type NxMenuItems = NxMenuItem[];

export interface NxMenuItem {
  id?        : string;
  name?      : string;
  label?     : string;
  icon?      : string;
  styleClass?: string;
  role?      : string[];
  routerLink?: string | string[];
  method?    : Function;
  hide?      : Function | boolean;
  disabled?  : boolean;
  items?     : NxMenuItem[];
  data?      : {};
  context?   : {};
  homeFor?   : string[];
  getPath?() : string;
}

export interface NxNavigationInfo {
  context?: any;
  label?  : string;
  icon?   : string;
}
