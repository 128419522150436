import { BaseEmitter } from './base-emitter';
import { NxObject }    from '.';

import * as xml2js from 'xml2js';

export const FileXmlReaderEvents = {
  item: 'item',
  done: 'done'
};

export class FileXmlReader extends BaseEmitter {

  canRead           : boolean;
  
  private _file     : File;
  private _reader   : FileReader;
  private _itemTag  : string;
  private _startTag : RegExp;
  private _endTag   : RegExp;
  private _wholeTag : RegExp;

  ///private reader    : FileLineReader;
  private fileLength: number;
  private items     : any[];
  private chunk     : string;
  private chunkSize : number = 1024;
  private start     : number = 0;
  count = 0;

  set file(file: File) {

    this._file      = file;
    this.fileLength = file.size;
    this.start      = 0;
    this.chunk      = '';
    this.canRead    = true;
  }

  private set reader(reader: FileReader) {

    this._reader        = reader;
    this._reader.onload = () => this.onChunkRead();
  }

  set itemTag(tag: string) {

    this._itemTag  = tag;
    this._startTag = new RegExp(`<[ ]*${tag}[a-zA-Z0-9="_ ]*>`);
    this._endTag   = new RegExp(`</[ ]*${tag}[a-zA-Z0-9="_ ]*>`);
    this._wholeTag = new RegExp(`${this._startTag.source}.*${this._endTag.source}`);

    this.chunk   = '';
    this.canRead = true;
  }

  constructor() {

    super();

    this.chunkSize = 1024;
    this.reader    = new FileReader();
  }

  /**
   * 
   * @param file 
   * @param itemTag 
   */
  read(file: File, itemTag: string): void {
    
    this.itemTag = itemTag;
    
    this.file = file;
    this.readChunk();
  }

  /**
   * 
   */
  private readChunk(): void {
  
    if (!this._file)
      return;
    
    // Extract a section of the file for reading starting at 'readPos' and
    // ending at 'readPos + chunkSize'
    var blob = this._file.slice(this.start, this.start + this.chunkSize);
  
    // Update our current read position
    this.start += this.chunkSize;

    // Read the blob as text
    //this.reader.readAsBinaryString(blob);
    this._reader.readAsText(blob);
  }

  /**
   * 
   */
  private onChunkRead() {

    this.manageLineEvent();

    if (!this.hasMoreData() || !this.canRead)
        return this.emit({ type: FileXmlReaderEvents.done });
      
    this.readChunk();
  }

  /**
   * 
   */
  private hasMoreData() {
    
    return this.start <= this._file.size;
  };

  /**
   * 
   * @param item
   */
  protected getEventData(item: NxObject): NxObject {

    return { item: item };
  }

  /**
   * 
   * @param chunk 
   */
  private manageLineEvent(): void {

    let tagIndex   : number;
    let match      : RegExpMatchArray;
    let line       : string  = this._reader.result.toString();
    let hasStartTag: boolean = this._startTag.test(line);

    if (!this.chunk && !hasStartTag)
      return;

    if (!this.chunk && hasStartTag) {

      tagIndex = line.match(this._startTag).index;

      line = line.substr(tagIndex);
    }

    if (!this._endTag.test(line)) {

      this.chunk += line;
      return;
    }

    match       = line.match(this._endTag);
    line        = line.substr(0, match.index) + match[0]; //`</${this._itemTag}>`;
    this.chunk += line;

    this.processItem();
  }

  /**
   * 
   */
  private processItem(): void {

    xml2js.parseString(this.chunk, (err, res) => {
      this.emit({ type: FileXmlReaderEvents.item, data: this.getEventData(res) });
    });

    this.chunk = '';
  }
}