import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[nxPreventDrop]'
})
export class NxPreventDropDirective {

  constructor() { }

  ngOnInit() { }

  @HostListener('document:drop', ['$event'])
  onDrop(event: Event): void {

    this.preventAndStop(event);
    this.getDataTransfer(event).clearData();
  }
  
  /**
   * 
   * @param event 
   */
  @HostListener('document:dragenter', ['$event'])
  onDragEnter(event: Event): void {

    this.preventAndStop(event);    
  }

  /**
   * 
   * @param event 
   */
  @HostListener('document:dragover', ['$event'])
  onDragOver(event: Event): void {

    this.preventAndStop(event);
  }

  /**
   * 
   * @param event 
   */
  @HostListener('document:dragleave', ['$event'])
  onDragLeave(event: Event): void {

    this.preventAndStop(event);
  }

  /**
   * 
   * @param event 
   */
  private getDataTransfer(event: Event | any): DataTransfer {

    return event.dataTransfer;
  }

  /**
   * 
   * @param event 
   */
  private preventAndStop(event: Event): any {

    event.preventDefault();
    event.stopPropagation();
  }
}
