import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { NxTooltipContainerComponent } from './nx-tooltip.component';
import { NxTooltipDirective } from './nx-tooltip.directive';
import { NxTooltipConfig } from './nx-tooltip.service';
import { ComponentLoaderFactory } from '../../utils/deps/utils/component-loader/component-loader.factory';
import { PositioningService } from '../../utils/deps/utils/positioning/positioning.service';

@NgModule({
  imports: [CommonModule],
  declarations: [NxTooltipDirective, NxTooltipContainerComponent],
  exports: [NxTooltipDirective],
  entryComponents: [NxTooltipContainerComponent]
})
export class NxTooltipModule {
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: NxTooltipModule,
      providers: [NxTooltipConfig, ComponentLoaderFactory, PositioningService]
    };
  }
}
