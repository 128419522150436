import { Component, Input, ContentChildren }        from '@angular/core';
import { QueryList, forwardRef, ViewEncapsulation } from '@angular/core';

// Models
import { NxAccordionConfig }                        from './nx-accordion-config';
// Components
import { NxAccordionItemComponent }                 from './nx-accordion-item.component';
import { NxAccordionService }                       from './nx-accordion.service';

@Component({
  selector     : 'nx-accordion',
  templateUrl  : './nx-accordion.component.html',
  styleUrls    : ['./nx-accordion.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers    : [ NxAccordionService ]
})
export class NxAccordionComponent {
 
  @Input() multiple: boolean = true;

  @ContentChildren(forwardRef(() => NxAccordionItemComponent)) items: QueryList<NxAccordionItemComponent>;

  constructor() {
	  
    NxAccordionConfig.serviceInstance = this;
  }

  /**
   * 
   * @param item 
   */
  didItemToggled(item: NxAccordionItemComponent): void {
    
    // on not multiple, it will collapse the rest of items
    if (!this.multiple) {
    	
      this.items.toArray().forEach((i) => {
        if (i !== item)
          i.applyToggle(true);
      });
    }
  }
}
