import { v4 as getUUID }                from 'uuid';
import * as _                           from 'lodash';

// Models
import { NxPropertyFunction, NxObject } from './classes';
import { NxValueType }                  from '../models';

/**
 * 
 * @param prop 
 * @param args 
 */
export function getNxPropertyValue<T>(prop: NxPropertyFunction<T> | T, args?: any[]): T {

  return (prop instanceof Function) ? prop(...args) : prop;
}

/**
 * 
 * @param type 
 */
export function isNxNumericType(type: NxValueType): boolean {

  let numericTypes = [NxValueType.NUMBER, NxValueType.DECIMAL, NxValueType.CURRENCY, NxValueType.FILESIZE, NxValueType.PERCENTAGE];

  return numericTypes.indexOf(type) !== -1;
}

/**
 * 
 * @param type 
 */
export function isNxTextType(type: NxValueType): boolean {

  let numericTypes = [NxValueType.EMAIL, NxValueType.LINK, NxValueType.STRING, NxValueType.STRING_WRAP];

  return numericTypes.indexOf(type) !== -1;
}

/**
 * 
 * @param type 
 */
export function isNxDateType(type: NxValueType): boolean {

  let numericTypes = [NxValueType.DATE, NxValueType.DATETIME];

  return numericTypes.indexOf(type) !== -1;
}

/**
 * Capitalize first char of string
 * @param text 
 */
export const capitalize = (text: string) => {

  if (!text)
    return;

  return text.charAt(0).toUpperCase() + text.slice(1);
}

/**
 * 
 */
export function uuid(): string {
  
  return getUUID();
}

/**
 * 
 * @param object 
 * @param path 
 */
export function deleteKey(object: any, path: string, cloneObj: boolean = true): any {

  const _obj = cloneObj ? clone(object) : object; //JSON.parse(JSON.stringify(object));
  const keys = path.split('.');

  keys.reduce((acc, key, index) => {

    if (!acc) return;

    if (index === keys.length - 1) {
      
      delete acc[key];
      return true;
    }

    return acc && acc[key];
  }, _obj);

  return _obj;
}

/**
 * 
 * @param data 
 */
export function clone(data: any): any {

  if (!data)
    return;

  return _.cloneDeep(data);
}

/**
 * 
 * @param object 
 * @param path 
 */
export const getAttr = (object: any, path: string | [string]): any => {
 
  return _.get(object, path);
}

/**
 * 
 * @param object 
 * @param path 
 * @param value 
 */
export const setAttr = (object: any, path: _.Many<string | number | symbol>, value: any): any => {

  return _.set(object, path, value);
}

/**
 * 
 * @param object 
 * @param path 
 */
export const hasAttr = (object: any, path: _.Many<string | number | symbol>): boolean => {

  return _.has(object, path);
}

/**
 * 
 * @param value 
 */
export const isEmpty = (value: any): boolean => {

  return _.isEmpty(value);
}

/**
 * 
 * @param value 
 * @param other 
 */
export function isEqual(value: any, other: any): boolean {

  return _.isEqual(value, other);
}

/**
 * 
 * @param data 
 * @param obj 
 */
export function findDeep(data: any[], attr: string, obj: NxObject) {

  return data.find((e) => {

    for (let k in obj) {

      if (e[k] !== obj[k])
        return (e[attr]) ? findDeep(e[attr], attr, obj) : false;
    }
    
    return true;
  });
}

/**
 * 
 * @param arr 
 * @param parseMethod 
 */
export function flattenArray(arr: any[], parseMethod?: Function): any[] {

  return arr.reduce((flat, toFlatten) => {

    if (Array.isArray(toFlatten))
      return flat.concat(flattenArray(toFlatten, parseMethod));

    return flat.concat(parseMethod ? parseMethod(toFlatten) : toFlatten);
  }, []);
}

/**
 * 
 * @param array 
 * @param properties 
 */
export function sortBy(array: any[], properties?: string | string[]): any[] {

  if (properties  && !Array.isArray(properties))
    properties = [properties];
  
  return array.sort((a, b) => {

    if (!properties) {

      if (a > b) return 1;
      if (a < b) return -1;
    }

    for (let i = 0; i < properties.length; i++) {

      let valueA = getAttr(a, properties[i]);
      let valueB = getAttr(b, properties[i]);

      if (valueA > valueB)
        return 1;
      else if (valueA < valueB)
        return -1;
    }

    return 0;
  });
}

/**
 * 
 * @param array 
 * @param searchString 
 * @param properties 
 * @param parseFn 
 */
export function filterBy(array: any[], searchString: string, properties: string[], parseFn: Function = (str: string) => str): any[] {

  if (!properties)
    return array;
  
  searchString = searchString.toLowerCase().trim();

  return array.filter((i) => {
  
    let text = '';
    
    properties.forEach(p => text += parseFn(getAttr(i, p) || ''));
    
    return text.toLowerCase().indexOf(searchString) !== -1;
  });
}

export  function isMobile() {
  const userAg = (navigator.userAgent || navigator.vendor || (window as any).opera);
  const check = (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i).test(userAg)||
  (/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i).test(userAg.substr(0,4));

  return check;
}

// /**
//  * 
//  * @param value 
//  * @param other 
//  */
// export function filterArray(value: any, other: any): boolean {
//   return _.find
// }
