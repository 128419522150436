import { Injectable, Injector }                                  from '@angular/core';
import { Location }                                              from '@angular/common';
import { Router, NavigationEnd, ActivatedRoute, Data }           from '@angular/router';
import { BehaviorSubject, Observable }                           from 'rxjs';

// Models
import { User }                                                  from '../../../admin/models';
import { NxMenuItem, NxMenuItems, NxNavigationInfo, MENU_ITEMS } from './nx-menu-item';
import { NxObject }                                              from '../../../common/utils';
// Services
import { AuthService }                                           from '../../../core/services/auth.service';

@Injectable()
export class NxNavigationService {

  // private user: User;
  private menu     : NxMenuItem;
  private menuItems: BehaviorSubject<NxMenuItem[]> = new BehaviorSubject<NxMenuItem[]>([]);
  private navInfo  : NxNavigationInfo              = {};

  routeId: string;

  set context(value: any)    { this.navInfo.context = value; };
  set label  (value: string) { this.navInfo.label   = value; };
  set icon   (value: string) { this.navInfo.icon    = value; };

  subject: BehaviorSubject<NxObject> = new BehaviorSubject<NxObject>(null);

  get section() { return this.subject.asObservable() };             
  
  constructor(injector              : Injector,
              private router        : Router,
              private location      : Location,
              private auth          : AuthService,
              private activatedRoute: ActivatedRoute) {


    this.menu = {
      id   : 'root',
      name : 'root', 
      label: 'base.navigation.home', 
      icon : 'mdi mdi-view-dashboard', 
      items: []
    };

    const menuConfig = injector.get(MENU_ITEMS);

    this.setupMenu(menuConfig);

    this.auth.getUser().subscribe(data => this.menuItems.next(this.setMenu(data)));  

    this.router.events.filter(event => event instanceof NavigationEnd).subscribe(event => {

      this.routeId = undefined;
      
      let root            : ActivatedRoute = this.activatedRoute.root;
      let context         : Data           = this.getRouteContextData(root)[0];
      let sectionContextId: Data           = this.getRouteContextData(root, [], 'contextId')[0];
  
      if (context && context.id)
        this.routeId = context.id;

      this.getContext(context, sectionContextId);
    });  
  }

  /**
   * 
   */
  getPath(role: string, path: string): string {

    return path;
  }
  
  /**
   * 
   */
  getMenu(): Observable<any[]> {

    return this.menuItems.asObservable();
  }

  /**
   * 
   */
  setMenu(user: User): NxMenuItem[] {

    return this.menu.items;
  }

  /**
   * 
   */
  getNavigationInfo(): NxNavigationInfo {

    return this.navInfo;
  }

  /**
   * 
   */
  sendToHome(): void {

    this.router.navigate(['/']);
  }

  sendToHomeByRole(roles): void {

    const homeMenu = this.menu.items.find(el => el.homeFor && el.homeFor.some(r=> roles.includes(r))) || { routerLink: '/' }; 
    
    this.router.navigate([homeMenu.routerLink]);
  }
  /**
   * 
   */
  sendToLogin(): void {

    this.auth.cleanSession();
    // this.router.navigate(['/login']);
  }

  /**
   * 
   */
  sendToError(code): void {

    this.router.navigate(['/error/' + code]);
  }

  /**
   * Concat all menu configuration set up by all navigation modules
   * to create the full menu structure
   * 
   * @param config 
   */
  private setupMenu(config: NxMenuItems[]): void {

    if (!config)
      return;

    this.menu.items = config.reduce((accumulator, items) => accumulator.concat(items), []);

    //console.log('menu', this.menu)
  }

  /**
   * 
   * @param route 
   * @param section 
   * @param ownProperty 
   */
  private getRouteContextData(route: ActivatedRoute, section: Data[] = [], ownProperty: string = 'id'): Data[] {
    
    // get the child routes
    let children: ActivatedRoute[] = route.children;

    // return if there are no more children
    if (children.length === 0)
      return section;

    // iterate over each children
    for (let child of children) {

      // verify the custom data property "breadcrumb" is specified on the route
      if (!child.snapshot.data.hasOwnProperty(ownProperty))
        return this.getRouteContextData(child, section);

      section.push(child.snapshot.data);

      // recursive
      return this.getRouteContextData(child, section);
    }
  }

  /**
   * 
   * @param context 
   */
  getContext(context, contextById): void {

    // DA OTTIMIZZARE (...DECISAMENTE)

    this.context = undefined; 
    this.label   = undefined;
    this.icon    = undefined;

    if (!context) {

      this.label = this.menu.label;
      this.icon  = this.menu.icon;
      return;
    }

    this.menu.items.forEach((section, index) => {
    
      let currentContext = section.items && section.items.find((item) => { 

        if (!item.id)
          return;

        return item.id === context.id;
      });

      if (contextById) {

        var sectionContext = section.items && section.items.find((item) => {

          if (!item.id)
            return;

          return item.id === contextById.contextId;
        });
      }
      
      if (sectionContext) {

        this.context = sectionContext.context;
        this.icon    = section.icon;
      }

      if (currentContext) {
       
        // this.context = (currentContext.context);
        this.label = currentContext.label;
        this.icon  = (currentContext.icon) ? currentContext.icon : section.icon;

        this.subject.next({section: section});
      }
    });

    if (!this.label && context.breadcrumb && context.breadcrumb[context.breadcrumb.length - 1]) {

      this.label = context.breadcrumb[context.breadcrumb.length - 1].label;

      if (context.breadcrumb[context.breadcrumb.length - 1].icon)
        this.icon = context.breadcrumb[context.breadcrumb.length - 1].icon;
    }
  }

  /**
   * 
   * @param label 
   */
  setLabel(label: string): void {

    this.label = label;
  }

  /**
   * 
   * @param icon 
   */
  setIcon(icon: string): void {

    this.icon = icon;
  }

  /**
   *
   */
  goBack(): void {

    this.location.back();
  }
}
