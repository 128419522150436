import { Injectable, ComponentFactoryResolver, Injector, Type } from '@angular/core';

// Models
import { NxModalItem, SECONDARY_MODAL_ID }                      from './models';
import { NxModalComponentOptions, NxModalOptions }              from './models';
// Services
import { NxModalStackService }                                  from './nx-modal-stack.service';

@Injectable({
  providedIn: 'root'
})
export class NxModalService {
  
  constructor(private moduleCFR : ComponentFactoryResolver, 
              private injector  : Injector, 
              private modalStack: NxModalStackService) { }
  
  /**
   * Close the selected modal by searching for the component
   * 
   * @param { String } modalId The id of the modal to close
   */
  close(modalId?: string): void {

    this.modalStack.close(modalId);
  }

  /**
   * Close all modals
   */
  closeAll(): void {
    
    this.modalStack.closeAll();
  }

  /**
   * Confirm the selected modal by searching for the component
   * @param modalId 
   */
  confirm(modalId?: string): void {

    this.modalStack.confirm(modalId);
  }

  /**
   * Open the specified modal based on the supplied modal id
   * 
   * @param { String } modalId The id of the modal to open
   */
  open(item: NxModalItem, modalId?: string): void {

    this.modalStack.open(this.moduleCFR, item, modalId);
  }

  /**
   * Open the specified modal based on the supplied modal id
   * Accepts options to build NxModalItem
   * 
   * @param component 
   * @param componentOptions 
   * @param modalOptions 
   * @param modalId 
   */
  openModal(component: Type<any> | Type<any>[], componentOptions: NxModalComponentOptions | NxModalComponentOptions[], modalOptions: NxModalOptions, modalId?: string): void {

    if (!component)
      return;
    
    this.modalStack.open(this.moduleCFR, new NxModalItem(component, componentOptions, modalOptions), modalId);
  }

  /**
   * Open secondary modal
   * 
   * @param component 
   * @param componentOptions 
   * @param modalOptions 
   */
  openSecondaryModal(component: Type<any> | Type<any>[], componentOptions: NxModalComponentOptions | NxModalComponentOptions[], modalOptions: NxModalOptions): void {

    this.openModal(component, componentOptions, modalOptions, SECONDARY_MODAL_ID);
  }

  /**
   * 
   * @param text 
   * @param button 
   */
  openConfirm(text: string, modalOptions: NxModalOptions): void {

    this.modalStack.openConfirm(this.moduleCFR, text, modalOptions);
  }
}
