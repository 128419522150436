import { ModuleWithProviders, NgModule } from '@angular/core';
import { ComponentLoaderFactory } from '../../utils/deps/utils/component-loader/component-loader.factory';

import { PositioningService } from '../../utils/deps/utils/positioning/positioning.service';
import { NxDropdownContainerComponent } from './nx-dropdown-container.component';
import { NxDropdownMenuDirective } from './nx-dropdown-menu.directive';
import { NxDropdownToggleDirective } from './nx-dropdown-toggle.directive';
import { NxDropdownConfig } from './nx-dropdown.config';

import { NxDropdownDirective } from './nx-dropdown.directive';
import { NxDropdownState } from './nx-dropdown.state';

@NgModule({
  declarations: [
  NxDropdownMenuDirective,
  NxDropdownToggleDirective,
  NxDropdownContainerComponent,
  NxDropdownDirective
  ],
  exports: [
  NxDropdownMenuDirective,
  NxDropdownToggleDirective,
  NxDropdownDirective
  ],
  entryComponents: [NxDropdownContainerComponent]
})
export class NxDropdownModule {
  public static forRoot(config?: any): ModuleWithProviders {
    return {
      ngModule: NxDropdownModule, providers: [
      ComponentLoaderFactory,
      PositioningService,
      NxDropdownState,
      {provide: NxDropdownConfig, useValue: config ? config : {autoClose: true}}
      ]
    };
  }
}
