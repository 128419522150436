
import gql from 'graphql-tag';

const userFields = `_id username profile { lastname firstname } services { password { expiresAt } } config roles functs operatorId operatorData`;

export const loginQuery = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      user { ${userFields} }
    }
  }
`;

export const logoutQuery = gql`
  mutation logout {
    logout
  }
`;

export const sessionQuery = gql`
  query sessionUser {
    sessionUser { ${userFields} }
  }
`;