// Models
import { NxBaseField }      from './nx-base-field';
import { NxFormInputTypes } from './nx-form.enums';

export class NxTimePickerField extends NxBaseField<string> {

  controlType = NxFormInputTypes.TIMEPICKER;
  
  placeholder: string;
  twelvehour : boolean;
  darktheme  : boolean;
  buttonLabel: string;
  options    : any;

  constructor(options: {} = {}) {

    super(options);
    this.placeholder = options['placeholder'] || 'components.nxTimePicker.selectedTime';
    this.twelvehour  = !!options['twelvehour'];
    this.darktheme   = !!options['darktheme'];
    this.buttonLabel = options['buttonLabel'] || 'common.buttons.confirm';
  }
}