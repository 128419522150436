import { CodedEntityBase } from '../../coded-entity/models';

export class AidNeedsType     extends CodedEntityBase {}
export class JobKind          extends CodedEntityBase {}
export class JobType          extends CodedEntityBase {}
export class Location         extends CodedEntityBase {}
export class PatientType      extends CodedEntityBase {}
export class PaymentStatus    extends CodedEntityBase {}
export class SessionKind      extends CodedEntityBase {}
export class SessionType      extends CodedEntityBase {
  internalCode: String;
}
export class SessionPriName   extends CodedEntityBase {}
export class TimeTable        extends CodedEntityBase {}

export interface AidNeedsTypeQueryResponse {
  aidNeedsTypeList?: AidNeedsType[];
  aidNeeds?        : AidNeedsType;
}

export interface JobKindQueryResponse {
  jobKindList?: JobKind[];
  jobKund?    : JobKind;
}

export interface JobTypeQueryResponse {
  jobTypeList?: JobType[];
  jobType?    : JobType;
}

export interface LocationQueryResponse {
  locationList?: Location[];
  location?    : Location;
}


export interface PatientTypeQueryResponse {
  patientTypeList?: PatientType[];
  patientType?    : PatientType;
}

export interface PaymentStatusQueryResponse {
  paymentStatusList?: PaymentStatus[];
  paymentStatus?    : PaymentStatus;
}

export interface SessionKindQueryResponse {
  sessionKindList?: SessionKind[];
  sessionKind?    : SessionKind;
}

export interface SessionTypeQueryResponse {
  sessionTypeList?: SessionType[];
  sessionType?    : SessionType;
}

export interface SessionPriNameQueryResponse {
  sessionPriNameList?: SessionPriName[];
  sessionPriName?    : SessionPriName;
}


export interface TimeTableQueryResponse {
  patientTypeList?: TimeTable[];
  patientType?    : TimeTable;
}






