import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nxBooleanValue'
})
export class NxBooleanValuePipe implements PipeTransform {

  transform(value: any, args?: any): any {

    return value ? 'common.yes' : 'common.no';
  }
}
