import { CodedEntity } from '../../coded-entity/models';


export enum OrganizationTypeCode {
  COMPANY = 'company'
}

export class OrganizationType implements CodedEntity {

  _id?           : string;
  id?            : number;
  code?          : string;
  name?          : string;
  

  constructor(obj: CodedEntity = {}) {
                      
    this._id            = obj._id;
    this.id             = obj.id;
    this.code           = obj.code;
    this.name           = obj.name;
    
  }
}
