import { Injectable, ComponentFactoryResolver } from '@angular/core';
import { Router, NavigationStart }              from '@angular/router';

// Models
import { CONFIRM_MODAL_ID, NxModalOptions }     from './models';
import { NxModalItem }                          from './models';
// Components
import { NxModalComponent }                     from './nx-modal.component';

@Injectable({
  providedIn: 'root'
})
export class NxModalStackService {

  private modals        : Map<string, NxModalComponent>;
  private mainModal     : NxModalComponent;
  private confirmModal  : NxModalComponent;
  private mainRouterElem: HTMLElement; 
  
  constructor(private router: Router) {

    this.modals = new Map<string, NxModalComponent>();
    
    this.setMainRouterElem();

    this.router.events.subscribe((event) => {

      // close all modals on navigation start
      if (event instanceof NavigationStart)
        this.closeAll();
    });
  }
  
  /**
   * Closes the selected modal by searching for the component
   * 
   * @param { String } modalId The id of the modal to close
   */
  close(modalId?: string): void {

    let modal = this.findModal(modalId);

    if (!modal)
      return;

    modal.close();
  }

  /**
   * Close all modals
   */
  closeAll(): void {

    this.confirmModal.close();
    this.modals.forEach(m => m.close());
  }

  /**
   * 
   * @param modalId 
   */
  confirm(modalId?: string): void {

    let modal = this.findModal(modalId);

    if (!modal)
      return;

    modal.confirm();
  }

  /**
   * 
   * @param modalId 
   */
  isMainModal(modalId: string): boolean {

    return this.mainModal.id === modalId;
  }

  /**
   * Locates the specified modal in the modals array
   * 
   * @param { String } modalId The id of the modal to find
   */
  findModal(modalId: string): NxModalComponent {

    if (this.modals.has(modalId))
      return this.modals.get(modalId);
    
    return this.mainModal;
  }

  /**
   * Opens the specified modal based on the supplied modal id
   * 
   * @param { String } modalId The id of the modal to open
   */
  open(moduleCFR: ComponentFactoryResolver, item: NxModalItem, modalId?: string): void {

    let modal = this.findModal(modalId);

    if (!modal)
      return;
    
    modal.open(moduleCFR, item);
  }

  /**
   * 
   * @param moduleCFR
   * @param text
   * @param options
   */
  openConfirm(moduleCFR: ComponentFactoryResolver, text: string, options: NxModalOptions): void {

    if (!this.confirmModal)
      return;
    
    options.centered = true;
    
    this.confirmModal.open(moduleCFR, new NxModalItem(null, [{ data: text }], options ));
  }

  /**
   * Registers all modal components being used on initialization, returns modal id
   * 
   * @param { Object } newModal The new modal to add to the array of available modals
   */
  registerModal(modal: NxModalComponent): void {

    if (!modal || !modal.id)
      return;

    if (modal.id === CONFIRM_MODAL_ID) {

      this.confirmModal = modal;
      return;
    }

    this.modals.set(modal.id, modal);

    if (!this.mainModal)
      this.mainModal = modal;
  }

  /**
   * 
   * @param modal 
   */
  unregisterModal(modal: NxModalComponent): void {

    if (!modal || !modal.id)
      return;

    this.modals.delete(modal.id);

    if (this.mainModal === modal)
      this.mainModal = null;
  }

  /**
   * Show / Hide main page content if full page modal is shown.
   * Page element is the next sibling of main router-outlet tag
   * 
   * @param open 
   */
  togglePageContent(show: boolean = false): void {

    if (!this.mainRouterElem)
      return;

    if (show)
      (<HTMLElement>this.mainRouterElem.nextElementSibling).style.display = '';
    else
      (<HTMLElement>this.mainRouterElem.nextElementSibling).style.display = 'none';
  }

  /**
   * Find main router-outlet element, in order to interact with main page content
   */
  private setMainRouterElem(): void {

    let mainElem = document.getElementsByTagName('main')[0];

    if (mainElem && mainElem.children[0])
      this.mainRouterElem = mainElem.children[0] as HTMLElement;
  }
}
