import { Directive, TemplateRef } from '@angular/core';

import { NxTabPillDirective } from './nx-tab-pill.directive';

/** Should be used to mark <template> element as a template for tab heading */
@Directive({selector: '[nxTabPillHeading]'})
export class NxTabPillHeadingDirective {
	
  public templateRef: TemplateRef<any>;

  public constructor(templateRef: TemplateRef<any>, tab: NxTabPillDirective) {
    
    tab.headingRef = templateRef;
  }
}
