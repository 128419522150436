import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nxObjectArray'
})
export class NxObjectArrayPipe implements PipeTransform {

  transform(value: any, keys: boolean, args?: any): any {

    if (!value)
      return [];
    
    let keyArr: any[] = Object.keys(value),
        dataArr = [];

    if (keys)
      return keyArr;
    // loop through the object,
    // pushing values to the return array
    keyArr.forEach((key: any) => dataArr.push(value[key]));

    // return the resulting array
    return dataArr;
  }
}
