export enum NxValueType {
  ID          = 'id',
  BADGE       = 'badge',
  CURRENCY    = 'currency', 
  DATE        = 'date', 
  DATETIME    = 'datetime', 
  DECIMAL     = 'decimal', 
  EMAIL       = 'email',
  FILESIZE    = 'filesize',
  HTML        = 'html', 
  ICON        = 'icon',
  LINK        = 'link', 
  NUMBER      = 'number',
  PERCENTAGE  = 'percentage',
  PROGRESS    = 'progress', 
  SELECT      = 'select', 
  STRING      = 'string',
  STRING_WRAP = 'stringwrap', 
  SWITCH      = 'switch', 
  BOOLEAN     = 'boolean'
}

export enum NxElementSize {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl'
}

export enum NxElementPosition {
  TOP    = 'top',
  BOTTOM = 'bottom',
  LEFT   = 'left',
  RIGHT  = 'right'
}

export enum NxElementState {
  INACTIVE = 'inactive',
  ACTIVE   = 'active',
  ENTER    = 'enter',
  LEAVE    = 'leave'
}

export enum NxAnimationState {
  IDLE  = 'idle',
  START = 'start',
  DONE  = 'done'
}

export enum PATIENT_TYPE  {
  ALCOHOLIC   = 'alcolista',
  ORTHOPEDICAL= 'ortopedico',
  NEUROLOGICAL= 'neurologico'
}

export enum SESSION_TYPE {
  INDIVIDUAL = 0,
  SUPERVISED = 1,
  GROUP      = 2
}
