import { NgModule, NO_ERRORS_SCHEMA, LOCALE_ID }              from '@angular/core';
import { BrowserModule }                                      from '@angular/platform-browser';
import { BrowserAnimationsModule }                            from '@angular/platform-browser/animations';
import { HttpClient }                                         from '@angular/common/http';
// Moment
import * as moment                                            from 'moment';
// Locale
import { registerLocaleData }                                 from '@angular/common';
import localeIT                                               from '@angular/common/locales/it';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader }                                from '@ngx-translate/http-loader';
// Modules
import { AppRoutingModule }                                   from './app-routing.module';
import { CoreModule }                                         from './core/core.module';
// Components
import { AppComponent }                                       from './app.component';
// Env
import { environment }                                        from '../environments/environment';

registerLocaleData(localeIT);

export function HttpLoaderFactory(http: HttpClient) { return new TranslateHttpLoader(http, './assets/i18n/', '.json'); }

// Base configuration object - temporary implementation
const NX_CONFIG = {
  baseUrl  : environment.baseUrl,
  version  : environment.version,
  build    : environment.build,
  api      : environment.api,
  endpoints: []
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule.forRoot(NX_CONFIG),
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [ HttpClient ] }
    })
  ],
  declarations: [ AppComponent ],
  providers   : [ { provide: LOCALE_ID, useValue: 'it' } ],
  bootstrap   : [ AppComponent ],
  schemas     : [ NO_ERRORS_SCHEMA ]
})
export class AppModule {

  constructor(translate: TranslateService) {

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.addLangs(['it', 'eng']);
    translate.setDefaultLang('it');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('it');
    
    // set moment locale
    moment.locale('it');
  }
}
