import { Component }        from '@angular/core';

// Models
import { CONFIRM_MODAL_ID } from '../models';
import { NxModalComponent } from '../nx-modal.component';
import { NxButton }         from 'src/app/common/utils';

@Component({
  selector   : 'nx-confirm-modal',
  templateUrl: './nx-confirm-modal.component.html',
  styleUrls  : ['./nx-confirm-modal.component.scss']
})
export class NxConfirmModalComponent extends NxModalComponent {

  id: string = CONFIRM_MODAL_ID;

  /**
   * Close modal
   */
  close(): void {

    if (this.options && this.options.cancel && this.options.cancel.method)
      this.options.cancel.method();

    this.modalComponent.hide();
  }

  /**
   * 
   */
  confirm(): void {
    
    if (this.options && this.options.confirm)
      this.options.confirm.method();

    this.modalComponent.hide();
  }

  /**
   * 
   * @param btn 
   */
  buttonFn(btn: NxButton): void {

    if (btn && btn.method)
      btn.method();

    this.modalComponent.hide();
  }

  /**
   * 
   */
  getMessage(): string {

    if (!this.item || !this.item.componentOptions)
      return '';

    return this.item.componentOptions[0].data;
  }
}
