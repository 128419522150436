import { NgModule, ModuleWithProviders, NO_ERRORS_SCHEMA } from '@angular/core';

import { NxBaseModalBackdropComponent } from './nx-base-modalBackdrop.component';
import { NxBaseModalDirective } from './nx-base-modal.directive';
import { PositioningService } from '../../utils/deps/utils/positioning/positioning.service';
import { ComponentLoaderFactory } from '../../utils/deps/utils/component-loader/component-loader.factory';
import { NxBaseModalContainerComponent } from './nx-base-modalContainer.component';
import { NxBaseModalService } from './nx-base-modal.service';

@NgModule({
  declarations: [
    NxBaseModalBackdropComponent, 
    NxBaseModalDirective, 
    NxBaseModalContainerComponent
  ],
  exports: [
    NxBaseModalBackdropComponent, 
    NxBaseModalDirective
  ],
  entryComponents: [
    NxBaseModalBackdropComponent, 
    NxBaseModalContainerComponent
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class NxBaseModalModule {
  
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule : NxBaseModalModule, 
      providers: [NxBaseModalService, ComponentLoaderFactory, PositioningService]
    };
  }
}
