// Models
import { NxBaseField }      from './nx-base-field';
import { NxFormInputTypes } from './nx-form.enums';

export class NxRadioField extends NxBaseField<string> {

  controlType = NxFormInputTypes.RADIO;
  
  options: { label: string, value: any }[] = [];
  inline : boolean;

  constructor(options: {} = {}) {

    super(options);
    this.options = options['options'] || [];
    this.inline  = !!options['inline'];
  }
}